import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate, useParams } from "react-router-dom";
import Delete from "../assets/delete.svg";
// @mui
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Drawer from "@mui/material/Drawer";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import {
  Box,
  Button,
  Card,
  CardHeader,
  // Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  Modal,
  OutlinedInput,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { useSnackbar } from "notistack";
import PostRequest from "../components/apiConnections/postRequest";
import Label from "../components/label";
import CircularProgress from "./CircularProgress.js";
// components
import Iconify from "../components/iconify";
import ModalDialog from "./ModalDialog.js";
// sections
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Carousel from "react-material-ui-carousel";
import GetRequest from "../components/apiConnections/getRequest.js";
import Loader from "../Loader";
import { AppCurrentVisits, AppRiskScore } from "../sections/@dashboard/app";
import { CustomNoRowsOverlay } from "./Assessment";
import "./css/Vendors.css";
import { useSelector, useDispatch } from "react-redux";
import { changeEcosystemTab } from "../redux/activeTabSlice.js";
import DocumentAnalysis from "./DocumentAnalysis.js";
import VendorUploadDocuments from "./VendorUploadDocuments.js";
import VendorRequestDocuments from "./VendorRequestDocuments.js";
import FaildeControl from './_failedControl';

// ----------------------------------------------------------------------
export default function VendorDetailsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ecosystemActiveTab = useSelector((state => state.activeTab?.ecosystem));
  const theme = useTheme();
  const modeClass =
    theme.palette.mode === "light" ? "light-mode-class" : "dark-mode-class";
  const data = useParams();
  const [openAssessment, setOpenAssessment] = React.useState(false);
  const [openRemediation, setOpenRemediation] = React.useState(false);
  // const [low, setLow] = useState(0);
  const [domainName, setDomainName] = useState("");
  const [hosting, setHosting] = useState("");
  const [address, setAddress] = useState("");
  const [dns, setDns] = useState("");
  const [breaches, setBreaches] = useState("");
  const [encryption, setEncryption] = useState("");
  const [misconfiguration, setMisconfigration] = useState("");
  const [patching, setPatching] = useState("");
  const [totalScore, setTotalScore] = useState("");
  const [dnsScore, setDnsScore] = useState("");
  const [breachesScore, setBreachesScore] = useState("");
  const [encryptionScore, setEncryptionScore] = useState("");
  const [misconfigrationScore, setMisconfigrationScore] = useState("");
  const [patchingScore, setPatchingScore] = useState("");
  const [dnsGrade, setDnsGrade] = useState("-");
  const [breachesGrade, setBreachesGrade] = useState("-");
  const [encryptionGrade, setEncryptionGrade] = useState("-");
  const [misconfigrationGrade, setMisconfigrationGrade] = useState("-");
  const [patchingGrade, setPatchingGrade] = useState("-");
  const [riskScore, setRiskScore] = useState("");
  const [issuesListData, setIssuesListData] = useState({});
  const [patchingRowsData, setPatchingRowsData] = useState({});
  const [totalLow, setTotalLow] = useState(0);
  const [totalMedium, setTotalMedium] = useState(0);
  const [totalHigh, setTotalHigh] = useState(0);
  const [totalCritical, setTotalCritical] = useState(0);
  const [logo, setLogo] = useState("/assets/images/avatars/user.png");
  const { enqueueSnackbar } = useSnackbar();
  const [userRoleId, setUserRoleId] = useState(localStorage.getItem("roleId"));
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [showLoader, setshowLoader] = useState(false);
  const [urlLinked, setUrlLinked] = useState(0);
  const [complianceData, setComplianceData] = useState([]);
  const [complianceRows, setComplianceRows] = useState([]);
  const [complianceColumns, setComplianceColumns] = useState([
    // { field: "uid", headerName: "No.", editable: false, maxWidth: 40 },
    // {
    //   field: "url",
    //   headerName: "Vendor",
    //   editable: false,
    //   width: 200,
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 1,
    // },
    {
      field: "email",
      headerName: "Email Id",
      editable: false,
      minWidth: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "set",
      headerName: "Set",
      editable: false,
      width: 200,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "score",
      headerName: "Score",
      editable: false,
      width: 50,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      editable: false,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "deadline",
      headerName: "Deadline",
      editable: false,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "assessmentStatus",
      headerName: "Status",
      editable: false,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              minWidth: "100px",
              height: "28px",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "12px",
              fontWeight: "500",
              color: "white",
              borderRadius: "5px",
              paddingY: "2px",
              paddingX: "6px",
              textTransform: "uppercase",
              // backgroundColor: `${params.row.completed[0]?.submitted
              //   ? "green"
              //   : params.row.delayed
              //     ? "red"
              //     : "#c3b50d"
              //   }`,
              backgroundColor: `${params?.row?.assessmentStatus
                ? "green"
                : params.row.delayed
                  ? "red"
                  : "#c3b50d"
                }`,
            }}
          >
            {params.row.assessmentStatus}
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              aria-label="more"
              aria-controls="actions-menu"
              aria-haspopup="true"
              onClick={(event) => handleActionClick(event, params.row)}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ]);
  const [centralizedRepoColumns, setCentralizedRepoColumns] = useState([
    // { field: "uid", headerName: "No.", editable: false, maxWidth: 40 },
    {
      field: "documentType",
      headerName: "Document Type",
      editable: false,
      width: 250,
      headerAlign: "left",
      align: "left",
      flex: 2,
      renderCell: (params) => params.row.documentType?.name || "N/A", // Safely access the name property
    },
    // {
    //   field: "documentName",
    //   headerName: "Document Name",
    //   editable: false,
    //   width: 200,
    //   headerAlign: "left",
    //   align: "left",
    //   flex: 1,
    // },
    {
      field: "createdAt",
      headerName: "Created At",
      editable: false,
      width: 50,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => formatDate(params.value)
    },
    // {
    //   field: "updatedAt",
    //   headerName: "Updated At",
    //   editable: false,
    //   width: 50,
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 1,
    //   renderCell: (params) => formatDate(params.value)
    // },
    {
      field: "action",
      headerName: "Action",
      width: 50,
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className=" icons-aligns-new icons-aligns  add-questionnaire-action mui-icons-questionnaire">
            <Link
              to={`${process.env.REACT_APP_BACKEND_BASE_URL}/${process.env.REACT_APP_OPEN_VENDOR_DOCUMENT}/${params.row.documentName}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>
                <VisibilityIcon />
              </Button>
            </Link>
          </Box>
        );
      },
    }
  ]);

  const [vendorDocumentsTypes, setVendorDocumentsTypes] = useState([]);
  const [vendorAvailableDocuments, setVendorAvailableDocuments] = useState([]); // State to store documents list fetched from the API
  const [vendorNotAvailableDocuments, setVendorNotAvailableDocuments] = useState([]);
  const [modelOpen, setModelOpen] = useState(false);
  const handleModelOpen = () => {
    if (vendorNotAvailableDocuments.length === 0) {
      enqueueSnackbar("All Documents already Uploaded", { variant: "info" });
    }
    else {
      setModelOpen(true);
    }
  }
  const handleModelClose = () => setModelOpen(false);
  const [contactPerson, setcontactPerson] = useState('');
  const [contactDetails, setContactDetails] = useState();
  const [formData, setFormData] = useState({
    documents: [{ documentType: '', isRequest: false, documentFile: null, documentName: null }],
  });
  const [showDocumentEvidence, setShowDocumentEvidence] = useState("Documents");
  const vendorQuestionnaireEvidenceColumn = [
    // { field: 'assessmentId', headerName: 'Assessment ID', width: 150 },
    { field: 'category', headerName: 'Category', width: 200 },
    { field: 'set', headerName: 'Set', width: 100 },
    {
      field: 'que', headerName: 'Question', width: 250,
      renderCell: (params) => params.row?.que?.text || "N/A", // Safely access the name property
    },
    { field: 'ans', headerName: 'Answer', width: 150, headerAlign: "center", align: "center" },
    { field: 'remark', headerName: 'Remark', width: 200 },
    {
      field: 'fileName',
      headerName: 'Action',
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Box className=" icons-aligns-new icons-aligns  add-questionnaire-action mui-icons-questionnaire">
            <Link
              to={`${process.env.REACT_APP_BACKEND_BASE_URL}/${"evidence"}/${params.row.fileName}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>
                <VisibilityIcon />
              </Button>
            </Link>
          </Box>
        );
      },
    },
  ];
  const [vendorQuestionnaireEvidenceData, setVendorQuestionnaireEvidenceData] = useState([]);
  const [errors, setErrors] = useState({});

  const transformVendorEvidenceData = (apiData) => {
    if (!apiData) return [];
    return apiData.flatMap((item) => {
      return Object.entries(item.questionnaires).flatMap(([category, questions]) =>
        questions.map((question) => ({
          id: `${item._id}-${question.que}`, // Create a unique ID for each row
          assessmentId: item.assessmentId,
          set: item.set,
          category,
          que: question.que,
          ans: question.ans,
          remark: question.remark,
          fileName: question.fileName,
        }))
      );
    });
  };

  const filteredRows = complianceRows
    .filter((row) => row.url === domainName)
    .map((val, i) => ({
      ...val,
      uid: i + 1,
    }));

  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleActionClick = (event, row) => {
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleActionClose = () => {
    setSelectedRow(null);
    setAnchorEl(null);
  };

  const handleOpen = (id, text) => {
    if (text === "compliance") {
      setAssessmentRemoveId(id);
    } else if (text === "remediation") {
      setRemediationRemoveId(id);
    }
  };

  const handleAnswerClick = (id, email, event) => {
    event.preventDefault();
    navigate(`/dashboard/view-answer-page/${id}`, { state: { email: email, prevUrl: `vendorDetails/${vendorUrl}` } });
  };

  const [remediationData, setRemediationData] = useState({});
  const userEmail = window.localStorage.getItem("userEmail");
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const [assessmentRemoveId, setAssessmentRemoveId] = useState(0);
  const [remediationRemoveId, setRemediationRemoveId] = useState(0);
  const [sumScore, setSumScore] = useState("");
  const [complianceLength, setComplianceLength] = useState(0);
  const [complianceCardData, setComplianceCardData] = useState([]);
  const [vendorType, setVendorType] = useState("");
  const [vendorTypes, setVendorTypes] = useState([]);
  const [type, setType] = useState("");
  const [activeIssue, setActiveIssue] = useState({});
  const [error, setError] = useState(false);
  const [disableDownloadPDFButton, setDisableDownloadPDFButton] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const [isPublicUrl, setIsPublicUrl] = useState(null);
  const [value, setValue] = useState("1");
  const [documentValue, setDocumentValue] = useState("11");

  useEffect(() => {
    getVendorDetails();
    getVendorComplianceCard();
    getVendorsType();
    getAssessment();
    getVendorDocuments();
    getVendorDocumentsTypes();
    getContactPerson();
    getVendorQuestionnaireEvidenceList();

    if (ecosystemActiveTab === "4") {
      setValue("4")
      dispatch(changeEcosystemTab("1"))
    }
    if (ecosystemActiveTab === "5") {
      setValue("5")
      dispatch(changeEcosystemTab("1"))
    }
  }, []);


  useEffect(() => {
    if (!showLoader) {
      setOpenAssessment(assessmentRemoveId !== 0 ? true : false);
      setOpenRemediation(remediationRemoveId !== 0 ? true : false);
    }
  }, [assessmentRemoveId, remediationRemoveId, showLoader]);

  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  useEffect(() => {
    if (vendorDocumentsTypes.length > 0) {
      let notAvailableDocuments = [];

      if (vendorAvailableDocuments.length === 0) {
        notAvailableDocuments = [...vendorDocumentsTypes];
      } else {
        const availableDocumentIds = vendorAvailableDocuments.map(
          (doc) => doc.documentType._id
        );
        notAvailableDocuments = vendorDocumentsTypes.filter(
          (doc) => !availableDocumentIds.includes(doc._id)
        );
      }
      setVendorNotAvailableDocuments(notAvailableDocuments);
    }
  }, [vendorDocumentsTypes, vendorAvailableDocuments]); // Re-run the effect when these dependencies change

  const handleClose = () => {
    setOpenAssessment(false);
    setOpenRemediation(false);
    setAssessmentRemoveId(0);
    setRemediationRemoveId(0);
  };

  const addLinked = async (e) => {
    e.preventDefault();
    setshowLoader(true);

    // Check if 'type' is selected
    if (!type) {
      enqueueSnackbar("Please select a type", { variant: "error" });
      setshowLoader(false);
      return;
    }

    try {
      const vendorPayload = {
        vendor: data.url,
        username: userEmail,
        vendorType: type,
      };

      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_VENDOR_URL_LINK}`,
          vendorPayload
        )
          .then((resLink) => {
            setTimeout(() => {
              // Reload the page after a successful response
              setshowLoader(true);
              window.location.reload();
            }, 500);
            setshowLoader(false);
          })
          .catch((errLink) => {
            enqueueSnackbar(errLink.message, { variant: "error" });
            setshowLoader(false);
          });
      }
    } catch (errApi) {
      enqueueSnackbar("Requested Url not found", { variant: "error" });
      setshowLoader(false);
    }
  };

  const getVendorsType = async () => {
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REQUEST_VENDOR_TYPES}`
        )
          .then((name) => {
            const obj = name.data.data;
            setVendorTypes(obj);
          })
          .catch((err) => {
            enqueueSnackbar("Vendor Types not find", { variant: "error" });
            return false;
          });
      }
    } catch (error) {
      enqueueSnackbar("Vendor Types Name not find", { variant: "error" });
      return false;
    }
  };

  const getVendorDetails = async () => {
    setMainLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const vendorPayload = {
        vendor: data.url,
      };
      let issuesRows = [];
      let patchingRows = [];
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REQUEST_SINGLE_VENDOR_DETAILS}`,
          vendorPayload
        )
          .then((res) => {
            var vendorTag = res.data.data?.vendorData.vendorType;

            if (!vendorTag) {
              vendorTag = "Vendor";
            }
            setIsPublicUrl(res.data.data?.vendorData?.thirdPartyPublicUrl)
            setVendorType(vendorTag);
            setUrlLinked(res.data.data?.urlLink);
            setDomainName(res.data.data?.vendorData?.vendorInfo.domain);
            setRiskScore(
              res.data.data?.vendorData?.vendorInfo?.score[0]?.Overall_score
            );
            setAddress(res.data.data?.vendorData?.vendorInfo?.ip);
            setHosting(res.data.data?.vendorData?.vendorInfo?.hosting);
            setLogo(
              res.data.data?.vendorData?.vendorInfo?.logo ||
              "/assets/images/avatars/user.png"
            );
            setDns(res.data.data?.vendorData?.vendorInfo?.count[0].Dns);
            setBreaches(
              res.data.data?.vendorData?.vendorInfo?.count[0].Breaches
            );
            setEncryption(
              res.data.data?.vendorData?.vendorInfo?.count[0].Encryption
            );
            setMisconfigration(
              res.data.data?.vendorData?.vendorInfo?.count[0].Misconfiguration
            );
            const vendorInfo = res.data.data?.vendorData?.vendorInfo;
            const dataMatch = [
              "breaches",
              "dns",
              "encryption",
              "misconfiguration",
              "patching",
            ];
            let totalLow = 0;
            let totalMedium = 0;
            let totalHigh = 0;
            let totalCritical = 0;
            let patchingissues = 0;

            Object.entries(vendorInfo).map((key, valuevendorInfo) => {
              if (dataMatch.includes(key[0])) {
                const dataArr = key[1];
                dataArr.map((value) => {
                  if (key[0] === "patching") {
                    if (
                      value.Issue === null ||
                      value.Issue === "" ||
                      value.Issue === "null"
                    ) {
                      totalLow = totalLow + 0;
                      totalMedium = totalMedium + 0;
                      totalHigh = totalHigh + 0;
                      totalCritical = totalCritical + 0;
                      patchingissues = patchingissues + 0;
                    } else {
                      const arrPatch = value.cve;
                      arrPatch.map((ele) => {
                        patchingRows.push({
                          ...issuespatchingList(
                            !ele.CVE ? "-" : ele.CVE,
                            ele.Description || ele.Desc,
                            String(ele?.Severity)
                              .replace(/<[^>]*>/g, "")
                              .replaceAll('"', "")
                              .trim()
                              .toLowerCase(),
                            "View",
                            ele,
                            ele.Score
                          ),
                          affectedUrl: value.Host,
                        });
                        patchingissues++;
                        totalLow =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "low"
                            ? totalLow + 1
                            : totalLow;
                        totalMedium =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "medium"
                            ? totalMedium + 1
                            : totalMedium;
                        totalHigh =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "high"
                            ? totalHigh + 1
                            : totalHigh;
                        totalCritical =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "critical"
                            ? totalCritical + 1
                            : totalCritical;
                      });
                    }
                  } else if (key[0] === "dns") {
                    if (
                      value.Issue === null ||
                      value.Issue === "" ||
                      value.Issue === "null"
                    ) {
                      totalLow = totalLow + 0;
                      totalMedium = totalMedium + 0;
                      totalHigh = totalHigh + 0;
                      totalCritical = totalCritical + 0;
                    } else {
                      totalLow =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "low"
                          ? totalLow + 1
                          : totalLow;
                      totalMedium =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "medium"
                          ? totalMedium + 1
                          : totalMedium;
                      totalHigh =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "high"
                          ? totalHigh + 1
                          : totalHigh;
                      totalCritical =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "critical"
                          ? totalCritical + 1
                          : totalCritical;
                      issuesRows.push(
                        issuesList(
                          !value.Issue ? "-" : value.Issue,
                          key[0],
                          String(value?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase(),
                          "View",
                          value,
                          value?.Reference,
                          value?.URL || []
                        )
                      );
                    }
                  } else {
                    if (
                      value.Issue === null ||
                      value.Issue === "" ||
                      value.Issue === "null"
                    ) {
                      totalLow = totalLow + 0;
                      totalMedium = totalMedium + 0;
                      totalHigh = totalHigh + 0;
                      totalCritical = totalCritical + 0;
                    } else {
                      totalLow =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "low"
                          ? totalLow + 1
                          : totalLow;
                      totalMedium =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "medium"
                          ? totalMedium + 1
                          : totalMedium;
                      totalHigh =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "high"
                          ? totalHigh + 1
                          : totalHigh;
                      totalCritical =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "critical"
                          ? totalCritical + 1
                          : totalCritical;
                      issuesRows.push(
                        issuesList(
                          !value.Issue ? "-" : value.Issue,
                          key[0],
                          String(value?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase(),
                          "View",
                          value,
                          value?.Reference,
                          value?.URL || []
                        )
                      );
                    }
                  }
                });
              }
              if (key[0] === "count") {
                setTotalScore(key[1][0].Overall_count);
              }
            });
            setPatchingRowsData(patchingRows);
            setIssuesListData(issuesRows);
            setTotalLow(totalLow);
            setTotalMedium(totalMedium);
            setTotalHigh(totalHigh);
            setTotalCritical(totalCritical);
            setPatching(res.data.data.vendorData.vendorInfo.count[0].Patching);
            // Score
            setDnsScore(res.data.data.vendorData.vendorInfo.score[0].Dns);
            setBreachesScore(
              res.data.data.vendorData.vendorInfo.score[0].Breaches
            );
            setEncryptionScore(
              res.data.data.vendorData.vendorInfo.score[0].Encryption
            );
            setMisconfigrationScore(
              res.data.data.vendorData.vendorInfo.score[0].Misconfiguration
            );
            setPatchingScore(
              res.data.data.vendorData.vendorInfo.score[0].Patching
            );
            if (res.data.data.vendorData.vendorInfo.grade) {
              setDnsGrade(res.data.data.vendorData.vendorInfo.grade[0].Dns);
              setBreachesGrade(
                res.data.data.vendorData.vendorInfo.grade[0].Breaches
              );
              setEncryptionGrade(
                res.data.data.vendorData.vendorInfo.grade[0].Encryption
              );
              setMisconfigrationGrade(
                res.data.data.vendorData.vendorInfo.grade[0].Misconfiguration
              );
              setPatchingGrade(
                res.data.data.vendorData.vendorInfo.grade[0].Patching
              );
            }
            getAssessment();
            setMainLoader(false);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Requested Url not find", { variant: "error" });
            setError(true);
            setMainLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setError(false);
      setMainLoader(false);
      return false;
    }
  };

  const getAssessment = async () => {
    setshowLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const assessmentPayload = {
        createdBy: userEmail,
        url: vendorUrl,
      };
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ASSESSMENT_BY_USER}`,
          assessmentPayload
        )
          .then((resAssessment) => {
            const obj = resAssessment.data.data;
            getRemediation();
            setComplianceLength(Object.keys(obj).length);
            setComplianceData(obj);
            setComplianceRows(
              obj.length
                ? [
                  ...obj?.map((d, i) => ({
                    ...d,
                    id: d._id,
                    score: `${d.score}/100`,
                    startDate: moment.unix(d.startDate).format("DD/MM/YYYY"),
                    deadline: moment.unix(d.deadline).format("DD/MM/YYYY"),
                    status: d.completed[0]?.submitted
                      ? "Completed"
                      : d.delayed
                        ? "Delayed"
                        : "In-Progress",
                  })),
                ]
                : []
            );
            setshowLoader(false);
            if (Object.keys(obj).length !== 0) {
              let sum = 0;
              let scoredAssessment = obj.filter((data) => typeof data.score === "number" && data.score > 0)
              scoredAssessment?.map((objKey, objVal) => (sum = sum + objKey.score));
              console.log(scoredAssessment.length, sum)
              setSumScore(sum ? Math.round(sum / scoredAssessment.length) : 0);
            } else {
              setSumScore(0);
            }
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Assessment not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      setError(true);
      return false;
    }
  };

  const deleteAssessment = async () => {
    const removeId = assessmentRemoveId;
    setshowLoader(true);
    try {
      const endpoint = process.env.REACT_APP_DELETE_ASSESSMENT;
      const assesmentdeletepayload = {
        assessmentId: removeId,
      };
      await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
        assesmentdeletepayload
      )
        .then((resVendors) => {
          enqueueSnackbar(resVendors.data.message, { variant: "success" });
          setshowLoader(false);
          handleClose();
          handleActionClose();
          getAssessment();
        })
        .catch((errVendor) => {
          enqueueSnackbar(errVendor.message, { variant: "error" });
          setshowLoader(false);
        });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
      setshowLoader(false);
    }
  };

  const deleteRemediation = async () => {
    const removeId = remediationRemoveId;

    setshowLoader(true);
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      {},
      "refreshToken"
    );
    if (refreshToken) {
      const endpoint = process.env.REACT_APP_DELETE_REMEDIATION;
      const remddeletepayload = {
        remediationId: removeId,
      };
      await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
        remddeletepayload
      )
        .then((resVendors) => {
          enqueueSnackbar(resVendors.data.message, { variant: "success" });
          setshowLoader(false);
          handleClose();
          setTimeout(() => {
            getRemediation();
          }, 1000);
        })
        .catch((errVendor) => {
          enqueueSnackbar(errVendor.message, { variant: "error" });
          setshowLoader(false);
        });
    }
  };

  const getRemediation = async () => {
    setshowLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const remediationPayload = {
        createdBy: userEmail,
        url: vendorUrl,
      };
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_REMEDIATION_PLAN_BY_USER}`,
          remediationPayload
        )
          .then((resRemediation) => {
            const obj2 = resRemediation.data.data;
            setRemediationData(obj2);
            setshowLoader(false);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Remediation not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Remediation not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const getVendorComplianceCard = async () => {
    setshowLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_COMPLIANCE_CARD}?domain=${vendorUrl}`
      )
        .then((res) => {
          const obj2 = res.data.data;
          let arr = [];
          for (let i = 0; i < Math.ceil(obj2.length / 3); i++) {
            let count = i * 3;
            arr.push(obj2.slice(count, count + 3));
          }
          setComplianceCardData(arr);
          setshowLoader(false);
        })
        .catch((err) => {
          // no data
          enqueueSnackbar("Compliance card not find", { variant: "error" });
          setshowLoader(false);
          return false;
        });
    } catch (error) {
      // no data
      enqueueSnackbar("Compliance card not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };


  const getVendorDocuments = async () => {
    setshowLoader(true);

    try {
      const vendorPayload = {
        vendorDomain: vendorUrl
      };

      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_DOCUMENT_BY_VENDOR_DOMAIN}`,
          vendorPayload)
          .then((documentDetails) => {
            setVendorAvailableDocuments(documentDetails?.data?.data);
            setshowLoader(false);
          })
          .catch((errApi) => {
            // enqueueSnackbar("Requested Url not found", { variant: "error" });
            setshowLoader(false);
          })
      }
    } catch (error) {
      // no data
      // enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      setError(true);
      return false;
    }
  };

  const getVendorDocumentsTypes = async () => {
    setshowLoader(true);
    try {

      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_DOCUMENT_TYPES}`)
          .then((response) => {
            setVendorDocumentsTypes(response?.data?.data);
            setshowLoader(false);
          })
          .catch((errApi) => {
            enqueueSnackbar("Requested Url not found", { variant: "error" });
            setshowLoader(false);
          })
      }
    } catch (error) {
      // no data
      // enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      setError(true);
      return false;
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "solid #0000",
    border: '2px solid #000',
    boxShadow: 24,
    textAlign: "center",
    borderRadius: "15px",
    p: 4,
    overflowY: "auto",
    maxHeight: "90%"
  };

  const getContactPerson = async () => {
    setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_DOMAIN_CONTACT_PERSON}?domain=${vendorUrl}`,
        )
          .then((res) => {
            const response = res.data.data;
            setContactDetails(response);
            setshowLoader(false);
          })
          .catch((err) => {
            enqueueSnackbar("Contact person not found", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      enqueueSnackbar("Contact person not found", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const getVendorQuestionnaireEvidenceList = async () => {
    setshowLoader(true);

    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_QUESTIONNAIRE_EVIDENCE_LIST}?vendorDomain=${vendorUrl}`,
        )
          .then((response) => {
            const transformedData = transformVendorEvidenceData(response?.data); // Transform the data
            setVendorQuestionnaireEvidenceData(transformedData); // Set the transformed data
            setshowLoader(false);
          })
          .catch((errApi) => {
            // enqueueSnackbar("Requested Url not found", { variant: "error" });
            setshowLoader(false);
          })
      }
    } catch (error) {
      // no data
      // enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      setError(true);
      return false;
    }
  };

  const getVendorGeneratedPDF = async () => {
    setshowLoader(true);
    setDisableDownloadPDFButton(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_GENERATED_PDF}?url=${vendorUrl}&createdBy=${userEmail}`,
          {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/pdf",
            },
          }
        )
          .then((res) => {
            const pdfData = new Blob([res.data], { type: "application/pdf" });
            const pdfUrl = URL.createObjectURL(pdfData);
            window.open(pdfUrl, "_blank");
            setDisableDownloadPDFButton(false);
            setshowLoader(false);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Error while generating PDF", { variant: "error" });
            setDisableDownloadPDFButton(false);
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      setDisableDownloadPDFButton(false);
      if (error.message) enqueueSnackbar("", { variant: "error" });
      else enqueueSnackbar("Error while generating PDF", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const validateURL = (url) => {
    // Regular expression to match the URL format
    const urlRegex =
      /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]{1,63})\.([a-zA-Z0-9-]{1,63})\.([a-zA-Z0-9]{2,6})(?:\/\S*)?$/;

    // Check if the URL matches the regex pattern
    const isValidURL = urlRegex.test(url);

    if (isValidURL) {
      // Split the URL into subdomain, domain, and TLD parts
      const [, subdomain, domain, tld] = url.match(urlRegex);

      // Check if subdomain, domain, and TLD meet the criteria
      if (subdomain && domain && tld) {
        // Check if the URL contains http or https protocol
        if (!url.startsWith("http://") && !url.startsWith("https://")) {
          return true;
        } else {
          return false; // Reject if the URL contains http or https protocol
        }
      } else {
        return false; // Reject if any part of the URL is missing
      }
    } else {
      return false; // Reject if the URL format doesn't match
    }
  };

  const visitPublicPage = async () => {
    const isExternal = validateURL(isPublicUrl);
    if (isExternal) {
      const url = `${window.location.protocol}//${isPublicUrl}`; // Example URL
      window.open(url, '_blank')
    } else {
      const url = `${window.location.protocol}//${process.env.REACT_APP_THIRDPARTY_HOSTNAME}/security-center/${isPublicUrl}`
      window.open(url, '_blank');
    }
  };

  function createData(category, issue, grade, score) {
    return { category, issue, grade, score };
  };

  const rows = [
    createData("Breaches", breaches, breachesGrade, breachesScore),
    createData("DNS", dns, dnsGrade, dnsScore),
    createData("Encryption", encryption, encryptionGrade, encryptionScore),
    createData(
      "Misconfiguration",
      misconfiguration,
      misconfigrationGrade,
      misconfigrationScore
    ),
    createData("Patching", patching, patchingGrade, patchingScore),
  ];

  function issuesList(name, category, severity, view, ele, refs, urls) {
    return { name, category, severity, view, ele, refs, urls };
  };

  function issuespatchingList(name, category, severity, view, ele, score) {
    return { name, category, severity, view, ele, score };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log('come into this', newValue)
  };

  const handleDocumentRequestChange = (event, newValue) => {
    setDocumentValue(newValue);
    console.log(newValue)
  };

  const ExpandableTableRow = ({
    children,
    refs,
    urls,
    cve,
    ele,
    expandComponent,
    ...otherProps
  }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <>
        <TableRow {...otherProps}>
          <TableCell padding="checkbox">
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {children}
        </TableRow>
        {isExpanded && (
          <TableRow>
            <TableCell colSpan="4" padding="checkbox">
              <TableContainer
                sx={{ p: 1 }}
                style={{ width: "93%", overflow: "hidden" }}
                className="vendordetail"
              >
                <TableRow>
                  <TableCell padding="checkbox" />
                  {expandComponent}

                  {ele?.Email ? (
                    <TableRow
                      sx={{
                        "& td": {
                          borderBottom: "1px solid rgba(81, 81, 81, 1)",
                        },
                      }}
                    >
                      <TableCell colSpan="5">
                        <b>Affected Emails</b>
                        <p>{ele?.Email}</p>
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {ele?.Source ? (
                    <TableRow sx={{ "& td": { border: 0 } }}>
                      <TableCell colSpan="5">
                        <b>Source</b>
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {ele?.Source && typeof ele?.Source == "object" ? (
                    ele?.Source.map((source, index) => (
                      <TableRow sx={{ "& td": { borderTop: 0 } }}>
                        <TableCell
                          colSpan="1"
                          sx={{ "& td": { borderBottom: 0, borderTop: 0 } }}
                        >
                          #{index + 1}
                        </TableCell>
                        <TableCell colSpan="4">{source}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow
                      sx={{ "& td": { border: 0 } }}
                    >
                      <TableCell>{ele?.Source}</TableCell>
                    </TableRow>
                  )}

                  {refs?.length > 0 ? (
                    <TableRow
                      sx={{
                        "& td": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell colSpan="5">
                        <b>References</b>
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {refs?.map((url, index) => (
                    <TableRow sx={{ "& td": { border: 0 } }}>
                      <TableCell colSpan="1">#{index + 1}</TableCell>
                      <TableCell colSpan="4">
                        <a href={url} target="_blank" rel="noreferrer">
                          {url}
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}

                  {urls?.length > 0 ? (
                    <TableRow
                      style={{ border: 0 }}
                    >
                      <TableCell colSpan="5" style={{ borderBottom: 0 }}>
                        <b>Affected URLs</b>
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {Array.isArray(urls) ? urls?.map((url, index) =>
                    index + 1 === urls?.length ? (
                      <TableRow sx={{ "& td": { border: 0 } }}>
                        <TableCell colSpan="1">#{index + 1}</TableCell>

                        <TableCell colSpan="4">
                          <a href={url} target="_blank" rel="noreferrer">
                            {url}
                          </a>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow sx={{ "& td": { border: 0 } }}>
                        <TableCell colSpan="1">#{index + 1}</TableCell>

                        <TableCell colSpan="4">
                          <a href={url} target="_blank" rel="noreferrer">
                            {url}
                          </a>
                        </TableCell>
                      </TableRow>
                    )
                  ) : null}
                </TableRow>
              </TableContainer>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  const ExpandableTableRowPatching = ({
    children,
    cve,
    expandComponent,
    ...otherProps
  }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
      <>
        <TableRow {...otherProps} hover>
          {children}
        </TableRow>
        {isExpanded && (
          <TableRow sx={{ "& td": { borderBottom: 0 } }}>
            <TableCell padding="checkbox" />
            {expandComponent}
          </TableRow>
        )}
      </>
    );
  };

  const ModalContentAssessment = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h3 className="send-assessments" style={{ whiteSpace: "nowrap" }}>
          Are you sure want to remove assessment ?
        </h3>
      </Typography>
    );
  };

  const ModalContentRemediation = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h3 className="send-assessments" >
          Are you sure want to remove remediation plan ?
        </h3>
      </Typography>
    );
  };

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open, event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const getLinkColor = () => {
    return theme.palette.mode === "dark" ? "white" : "black";
  };

  const linkDynamicStyle = {
    color: getLinkColor(),
    textDecoration: "underline",
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  };

  const list = (anchor, data) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 700,
        overflowX: "hidden",
      }}
      role="presentation"
      onKeyDown={(event) => toggleDrawer(anchor, false, event)}
    >
      <CloseIcon
        className="space-cross"
        onClick={(event) => toggleDrawer(anchor, false, event)}
      />
      <div className="tab-anchor-flex">
        <div>
          <h4>Name</h4>
          <p>{data.name}</p>
        </div>
        <div>
          <h4>Severity</h4>
          <Label
            color={
              data.severity === "low"
                ? "success"
                : data.severity === "medium"
                  ? "warning"
                  : data.severity === "high"
                    ? "default"
                    : data.severity === "critical"
                      ? "error"
                      : "secondary"
            }
          >
            {data.severity}
          </Label>
        </div>
        <div>
          <h4>Score</h4>
          <p>{data.score}</p>
        </div>
      </div>
      <div className="in-dataspace">
        <Box>
          <Typography>CPE</Typography>
          <Box>{data?.ele?.CPE}</Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography>CWE</Typography>
          <Box>{data?.ele?.CWE}</Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography>Description</Typography>
          <Box>{data.category}</Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography>Technology</Typography>
          <Box>{data.ele?.Technology}</Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography>Affected URL</Typography>
          <Box>
            {data.affectedUrl ? (
              <a
                href={
                  data.affectedUrl.startsWith("https://")
                    ? data.affectedUrl
                    : `https://${data.affectedUrl}`
                }
                target="_blank"
                rel="noreferrer"
                style={linkDynamicStyle}
              >
                <Chip
                  label={data.affectedUrl}
                  color="error"
                  sx={{
                    "&:hover": {
                      cursor: "pointer", // Change cursor on hover
                    },
                  }}
                />
              </a>
            ) : (
              "---"
            )}
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography>Remediation</Typography>
          <Box>{data.ele?.Remediation}</Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography>Advisory Links</Typography>
          <Box>
            {data.ele?.Links?.map((link, i) => (
              <Box key={i}>
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={linkDynamicStyle}
                >
                  {link}
                </a>
              </Box>
            ))}
          </Box>
        </Box>
      </div>
    </Box>
  );

  const handleDocumentorEvidenceChange = (event) => {
    setShowDocumentEvidence(event.target.value);
  };

  const handleModelCloseFunction = () => {
    setModelOpen(false);
    setFormData({
      documents: [{ documentType: '', isUpload: false, documentFile: null, documentName: null, }],
    })
    setcontactPerson(null);
    setErrors({});
  }

  return (
    <>
      <Loader show={mainLoader || showLoader} />

      <Helmet>
        <title> Vendor's Detail | Genesis Platform</title>
      </Helmet>

      <Container maxWidth={false}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          <span className="heading-main">Vendor's Details</span>
        </Typography>
        {domainName !== "" && domainName && (
          <Box
            sx={{ width: "100%", typography: "body1" }}
            className="ipad-space"
          >
            <TabContext value={value}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="tabspart"
              >
                {userRoleId === "1" ? (
                  <>
                    <TabList
                      onChange={handleChange}
                      variant="scrollable"
                      allowScrollButtonsMobile
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Home" value="1" />
                      <Tab label="Issues" value="2" />
                      <Tab label="CVE" value="3" />
                      <Tab label="Compliance" value="4" />
                      <Tab label="Remediation" value="5" />
                      <Tab label="Centralized Data" value="6" />
                      <Tab label="Failed Control" value="7" />
                    </TabList>
                  </>
                ) : userRoleId === "2" ? (
                  <>
                    <TabList
                      onChange={handleChange}
                      variant="scrollable"
                      allowScrollButtonsMobile
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Home" value="1" />
                      <Tab label="Issues" value="2" />
                      <Tab label="CVE" value="3" />
                    </TabList>
                  </>
                ) : null}
              </Box>
              <TabPanel value="1">
                <Grid container spacing={1} pb={2}>
                  <Grid item xs={4} sm={2} md={1} lg={1} sx={{ mt: 2 }}>
                    <img
                      src={logo}
                      alt="login"
                      style={{
                        width: "70px",
                        height: "70px",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.05)",
                      }}
                    />
                  </Grid>
                  <Grid item xs={8} sm={10} md={11} lg={11} sx={{ mt: 2 }}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div className="domain-part">
                        <div className="flex-domain">
                          <div>
                            <h3 className="domain-name">{domainName}</h3>
                            <p className="add-top">{address}</p>
                          </div>
                          <div className="domain-label-left">
                            <Label color={vendorType && "success"}>
                              {vendorType}
                            </Label>
                          </div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'center', gap: 5 }}>
                        {isPublicUrl && (
                          <Button
                            variant="contained"
                            className="executive-report-btn"
                            startIcon={
                              <Iconify icon="material-symbols:preview" />
                            }
                            disabled={disableDownloadPDFButton}
                            onClick={visitPublicPage}
                          >
                            Security Center
                          </Button>
                        )}
                        <DocumentAnalysis domain={vendorUrl}></DocumentAnalysis>
                        <Button
                          variant="contained"
                          className="executive-report-btn"
                          startIcon={
                            <Iconify icon="material-symbols:download" />
                          }
                          disabled={disableDownloadPDFButton}
                          onClick={() => getVendorGeneratedPDF()}
                        >
                          Executive Report
                        </Button>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={3} md={8} lg={8}></Grid>
                  <Grid item xs={12} sm={9} md={4} lg={4} >
                    {/* {userRoleId == 1 ? (
                      urlLinked == 0 ? (
                        <div
                          className="flex-domain"
                          style={{ marginTop: "10px" }}
                        >
                          <FormControl sx={{ mx: 2, mt: 1 }}>
                            <Select
                              value={type || ""}
                              onChange={handleChangeType}
                              displayEmpty
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <em>Select Type</em>;
                                }
                                const selectedVendorType = vendorTypes.find(
                                  (vt) => vt._id === selected
                                );
                                return selectedVendorType
                                  ? selectedVendorType.vendorType
                                  : "";
                              }}
                            >
                              {vendorTypes.map((vendorType) => (
                                <MenuItem
                                  key={vendorType._id}
                                  value={vendorType._id}
                                >
                                  {vendorType.vendorType}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <Button
                            variant="contained"
                            className="addnew ad-domain-btn"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                            onClick={(e) => {
                              addLinked(e);
                            }}
                          >
                            Add New
                          </Button>
                        </div>
                      ) : null
                    ) : null} */}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} lg={4} className="flx-property">
                    <AppCurrentVisits
                      title="Issue Severity"
                      chartData={[
                        { label: "Low", value: totalLow },
                        { label: "Medium", value: totalMedium },
                        { label: "High", value: totalHigh },
                        { label: "Critical", value: totalCritical },
                      ]}
                      chartColors={["#6AA84F", "#FEB019", "#ff7300", "#d32f2f"]}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} className="flx-property">
                    <AppRiskScore
                      title="Risk score"
                      chartData={
                        [
                          // { label: 'Low', value: 4344 },
                          // { label: 'Medium', value: 5435 },
                          // { label: 'High', value: 1443 },
                        ]
                      }
                      riskScore={
                        complianceLength !== 0 && sumScore > 0
                          ? Math.round((riskScore + sumScore) / 2)
                          : Math.round(riskScore)
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4} lg={4} className="flx-property">
                    <Card className="manage-middle box-border-0">
                      <CardHeader title={"Total score"} subheader={""} />
                      <div className="score-flx">
                        <div className="fullcover-width border-right-1">
                          <CircularProgress percent={sumScore} />
                          <CardHeader
                            title={"Inside-out score"}
                            subheader={""}
                            sx={{ p: "0" }}
                          />
                        </div>
                        <div className="fullcover-width">
                          <CircularProgress percent={riskScore} />
                          <CardHeader
                            title={"Outside-in score"}
                            subheader={""}
                            sx={{ p: "0" }}
                          />
                        </div>
                        {/*<ReactApexChart options={options} series={series} type="donut" height={250} /> */}
                      </div>
                    </Card>
                    {/*<Card className="manage-middle box-border-0">
                        <CardHeader title={"Outside-in score"} subheader={""} /> */}
                    {/* <CircularProgress
                          percent={(complianceLength !== 0 && sumScore > 0) ?
                          Math.round((riskScore + sumScore) / 2)
                          : Math.round(riskScore)}
                          title={''}
                        /> */}
                    {/* <ReactApexChart options={options} series={series} type="donut" height={250} /> */}
                    {/* </Card> */}
                  </Grid>

                  <Grid item xs={12} md={9} lg={9} className="flx-property">
                    <Card className="manage-middle">
                      <CardHeader title={"Compliance Card"} subheader={""} />
                      <div className="flx-property">
                        {complianceCardData.length ? (
                          <Box
                          // sx={{display:"flex", justifyContent:"center",width:"100%"}}
                          >
                            <Carousel
                              sx={{ width: "700px" }}
                              navButtonsAlwaysVisible={true}
                              autoPlay={false}
                              indicators={false}
                            >
                              {complianceCardData?.map((card, i) => {
                                return (
                                  <Box
                                    key={i}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {card.length
                                      ? card?.map((item, j) => {
                                        return (
                                          <Box
                                            sx={{ marginX: "10px" }}
                                            key={j}
                                          >
                                            <h3>{item.name}</h3>
                                            <img
                                              src={`${process.env
                                                .REACT_APP_BACKEND_BASE_URL
                                                }${item.complianceType ==
                                                  "Others"
                                                  ? process.env
                                                    .REACT_APP_STATIC_PATH_COMPLIANCE_CARD_LOGO
                                                  : process.env
                                                    .REACT_APP_STATIC_PATH_ASSETS
                                                }/${item.logo}`}
                                              className="audits-images"
                                              alt="logo"
                                            />
                                            <a
                                              target="_blank"
                                              rel="noreferrer"
                                              href={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH_COMPLIANCE_CARD_DOCUMENT}/${item.document}`}
                                            >
                                              <p className="link-circle">
                                                <KeyboardArrowRightIcon />
                                              </p>
                                            </a>
                                          </Box>
                                        );
                                      })
                                      : null}
                                  </Box>
                                );
                              })}
                            </Carousel>
                          </Box>
                        ) : (
                          <div className="no-card-data">
                            <p>No Compliance Data Found</p>
                          </div>
                        )}
                      </div>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={3} lg={3} className="flx-property">
                    <Card className="manage-middle">
                      <CardHeader title={"Total Issues"} subheader={""} />
                      <Typography variant="h1">
                        <span className="total-score">
                          {totalScore ? totalScore : 0}
                        </span>
                      </Typography>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <TableContainer
                      component={Paper}
                      sx={{ p: 1 }}
                      className="vendordetail"
                    >
                      <Table sx={{ minWidth: 100 }} aria-label="simple table">
                        <TableHead>
                          <CardHeader
                            title={"Category Scores"}
                            subheader={""}
                          />
                          <TableRow>
                            <TableCell sx={{ maxWidth: 20 }}>
                              Category
                            </TableCell>
                            <TableCell>Issue</TableCell>
                            <TableCell>Grade</TableCell>
                            <TableCell align="right">Score</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {rows.map((row) => (
                            <TableRow
                              key={row.category}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                sx={{ maxWidth: 20 }}
                                component="th"
                                scope="row"
                              >
                                {row.category}
                              </TableCell>
                              <TableCell
                                sx={{ maxWidth: 20 }}
                                component="th"
                                scope="row"
                              >
                                {row.issue}
                              </TableCell>
                              <TableCell>
                                <span className="row-grades">{row.grade}</span>
                              </TableCell>
                              <TableCell align="right">{row.score}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12} pl={0} className="font-p">
                    <h2>All Issues</h2>
                    <p>
                      <strong>Host - </strong> {domainName}
                      <br></br>
                      <strong>IP Address - </strong> {address}
                    </p>
                    <TableContainer
                      component={Paper}
                      sx={{ p: 1 }}
                      className="vendordetail"
                    >
                      <Table sx={{ minWidth: 100 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell sx={{ maxWidth: 80 }}>Name</TableCell>
                            <TableCell align="left">Category</TableCell>
                            <TableCell align="left">Severity</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {issuesListData.map((issueRow) => (
                            <ExpandableTableRow
                              key={issueRow.name}
                              refs={issueRow.refs}
                              ele={issueRow.ele}
                              urls={issueRow.urls}
                              expandComponent={
                                <>
                                  <TableRow sx={{ "& td": { border: 0 } }}>
                                    <TableCell colSpan="5">
                                      <b>Name</b>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell colSpan="5">
                                      {issueRow.ele?.Issue}
                                    </TableCell>
                                  </TableRow>

                                  {issueRow.ele?.Data ? (
                                    <>
                                      <TableRow sx={{ "& td": { border: 0 } }}>
                                        <TableCell colSpan="5">
                                          <b>Data</b>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell colSpan="5">
                                          {issueRow.ele?.Data}
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  ) : null}

                                  <TableRow sx={{ "& td": { border: 0 } }}>
                                    <TableCell colSpan="5">
                                      <b>Description</b>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell colSpan="5">
                                      {issueRow.ele?.Description}
                                    </TableCell>
                                  </TableRow>

                                  <TableRow sx={{ "& td": { border: 0 } }}>
                                    <TableCell colSpan="5">
                                      <b>Remediation</b>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell colSpan="5">
                                      {issueRow.ele?.Remediation}
                                    </TableCell>
                                  </TableRow>
                                </>
                              }
                            >
                              <TableCell component="th" scope="row">
                                {issueRow.name}
                              </TableCell>
                              <TableCell align="left">
                                {issueRow.category}
                              </TableCell>
                              <TableCell align="left">
                                <Label
                                  color={
                                    issueRow.severity === "low"
                                      ? "success"
                                      : issueRow.severity === "medium"
                                        ? "warning"
                                        : issueRow.severity === "high"
                                          ? "default"
                                          : issueRow.severity === "critical"
                                            ? "error"
                                            : "secondary"
                                  }
                                >
                                  {issueRow.severity}
                                </Label>
                              </TableCell>
                            </ExpandableTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="3">
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    className={`font-p ${modeClass}`}
                  >
                    <h2>CVE Profile</h2>
                    <p>
                      <strong>Host - </strong> {domainName}
                      <br></br>
                      <strong>IP Address - </strong> {address}
                    </p>

                    <TableContainer
                      // component={Paper}
                      sx={{ p: 1 }}
                      className="vendordetail"
                    >
                      <Table sx={{ minWidth: 100 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            {/* <TableCell >#</TableCell> */}
                            <TableCell align="left" width="33.33%">
                              Name
                            </TableCell>
                            <TableCell align="left" width="33.33%">
                              Technology
                            </TableCell>
                            <TableCell align="left" width="33.33%">
                              Severity
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {patchingRowsData.length === 0 ? (
                            <TableCell
                              component="th"
                              colSpan={3}
                              align="center"
                              height="10px"
                              style={{ padding: "70px" }}
                            >
                              No Data Found
                            </TableCell>
                          ) : (
                            patchingRowsData.map((issueRow, i) => (
                              <ExpandableTableRowPatching key={i + 1}>
                                {["right"].map((anchor, j) => (
                                  <React.Fragment key={j}>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      align="left"
                                      width="33.33%"
                                      className="anchor-click"
                                      onClick={(event) => {
                                        toggleDrawer(anchor, true, event);
                                        setActiveIssue(issueRow);
                                      }}
                                    >
                                      {issueRow.name}
                                    </TableCell>
                                  </React.Fragment>
                                ))}

                                {["right"].map((anchor, j) => (
                                  <React.Fragment key={j}>
                                    <TableCell
                                      align="left"
                                      width="33.33%"
                                      className="anchor-click"
                                      onClick={(event) => {
                                        toggleDrawer(anchor, true, event);
                                        setActiveIssue(issueRow);
                                      }}
                                    >
                                      {issueRow.ele.Technology &&
                                        issueRow.ele.Technology !== " " &&
                                        issueRow.ele.Technology !== "  " &&
                                        issueRow.ele.Technology !== ""
                                        ? issueRow.ele.Technology
                                        : "---------"}
                                    </TableCell>
                                  </React.Fragment>
                                ))}
                                {["right"].map((anchor, j) => (
                                  <React.Fragment key={j}>
                                    <TableCell
                                      align="left"
                                      width="33.33%"
                                      className="anchor-click"
                                      onClick={(event) => {
                                        toggleDrawer(anchor, true, event);
                                        setActiveIssue(issueRow);
                                      }}
                                    >
                                      <Label
                                        color={
                                          issueRow.severity === "low"
                                            ? "success"
                                            : issueRow.severity === "medium"
                                              ? "warning"
                                              : issueRow.severity === "high"
                                                ? "default"
                                                : issueRow.severity === "critical"
                                                  ? "error"
                                                  : "secondary"
                                        }
                                      >
                                        {issueRow.severity}
                                      </Label>
                                    </TableCell>
                                  </React.Fragment>
                                ))}
                              </ExpandableTableRowPatching>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </TabPanel>
              {userRoleId === "1" ? (
                <>
                  <TabPanel value="4">
                    <div className="compliance-inline">
                      <h2>Compliance</h2>
                      {/* <Link to={`../vendor-information/${vendorUrl}`}> */}
                      <Button
                        variant="contained"
                        className="addnew send-assessment"
                        onClick={() => navigate(`../vendor-information/${vendorUrl}`, { state: `vendorDetails/${vendorUrl}` })}
                      >
                        Send Assessment{" "}
                      </Button>
                      {/* </Link> */}
                    </div>
                    {/* {complianceData.length > 0 ? (
                      <div className="Assessment-box">
                        {complianceData.filter(key => key.url === domainName).map((key, val) => (
                          <>
                            <Grid
                              container
                              columns={0}
                              columnSpacing={3}
                              className="box-Assessment"
                            >
                              <Grid item xs={6} md={3} lg={3}>
                                <div className="widthboxes text-align">
                                  <h5>Vendor</h5>
                                  <p>{key.url}</p>
                                </div>
                              </Grid>
                              <Grid item xs={6} md={3} lg={3}>
                                <div className="widthboxes text-align">
                                  <h5>Email</h5>
                                  <p>{key.email}</p>
                                </div>
                              </Grid>
                              <Grid item xs={6} md={2} lg={2}>
                                <div className="widthboxes text-align">
                                  <h5>Set</h5>
                                  <p>{key.set}</p>
                                </div>
                              </Grid>
                              <Grid item xs={6} md={4} lg={4}>
                                <div style={{ display: "flex" }}>
                                  <div className="widthboxes text-align">
                                    <h5>Score</h5>
                                    <p>{key.score}/100</p>
                                  </div>
                                  <div className="widthboxes text-align">
                                    <h5>Status</h5>
                                    <p
                                      style={{
                                        color: `${
                                          key.completed[0]?.submitted
                                            ? "#0ce90c"
                                            : key.delayed
                                            ? "#fd0000"
                                            : "#ff9e00"
                                        }`,
                                      }}
                                      className="text-align"
                                    >
                                      {key.completed[0]?.submitted
                                        ? "Completed"
                                        : key.delayed
                                        ? "Delayed"
                                        : "In-Progress"}
                                    </p>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xs={6} md={3} lg={3}>
                                <div className="widthboxes text-align">
                                  <h5>Start Date</h5>
                                  <p>
                                    {moment(
                                      new Date(key.startDate * 1000)
                                    ).format("MMMM Do YYYY")}
                                  </p>
                                </div>
                              </Grid>
                              <Grid item xs={6} md={4} lg={4}>
                                <div className="widthboxes text-align">
                                  <h5>Deadline</h5>
                                  <p>
                                    {moment(
                                      new Date(key.deadline * 1000)
                                    ).format("MMMM Do YYYY")}
                                  </p>
                                </div>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                md={3}
                                lg={3}
                                className="mob-view"
                              >
                                <div className="widthboxes svg-color">
                                  <h5 className="text-align">Action</h5>
                                  <div
                                    className="icons-aligns"
                                    style={{
                                      justifyContent: "center",
                                    }}
                                  >
                                    <a href={`../security-control/${key._id}`}>
                                      Security
                                    </a>
                                    <a
                                      href="#"
                                      onClick={(event) =>
                                        handleAnswerClick(
                                          key._id,
                                          key.email,
                                          event
                                        )
                                      }
                                    >
                                      Answer
                                    </a>
                                    <a
                                      href="#"
                                      className="remove-color"
                                      onClick={() =>
                                        handleOpen(key._id, "compliance")
                                      }
                                    >
                                      Remove
                                    </a>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </>
                        ))}
                      </div>
                    ) : (
                      <div className="Assessment-box">
                        <div className="manage-mid">
                          <h2>No Assessment Found!</h2>
                          <Link to={`../vendor-information/${vendorUrl}`}>
                            <Button
                              variant="contained"
                              className="addnew click-to-begin"
                            >
                              Click to Begin{" "}
                            </Button>
                          </Link>
                        </div>
                      </div>
                    )} */}
                    <Box
                      className="bg-dark-assessment"
                      sx={{
                        width: "100%",
                        backgroundColor: "#fff",
                        border: "none",
                        borderRadius: "15px",
                      }}
                    >
                      <DataGrid
                        sx={{ border: "none", width: "100%", height: "400px" }}
                        rows={filteredRows}
                        columns={complianceColumns}
                        components={{
                          NoRowsOverlay: () => (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                textAlign: "center",
                                fontSize: "18px",
                              }}
                            >
                              No data
                            </Box>
                          ),
                        }}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 5,
                            },
                          },
                          filter: {
                            filterModel: {
                              items: [],
                              quickFilterValues: [],
                            },
                          },
                        }}
                        autosizeOptions={{
                          columns: ["id", "firstName", "lastName"],
                          includeOutliers: true,
                          includeHeaders: false,
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        slots={{
                          toolbar: GridToolbar,
                          noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                          },
                        }}
                        disableRowSelectionOnClick
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableColumnMenu
                      />
                      {selectedRow && (
                        <Menu
                          id="actions-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleActionClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <MenuItem>
                            <a
                              // href={`../security-control/${selectedRow._id}`}
                              onClick={() => navigate(`../security-control/${selectedRow._id}`, { state: `vendorDetails/${vendorUrl}` })}
                              className={
                                theme.palette.mode === "dark"
                                  ? "dark-menu-item-btn"
                                  : "light-menu-item-btn"
                              }
                            >
                              Security
                            </a>
                          </MenuItem>
                          <MenuItem>
                            <a
                              href="#"
                              onClick={(event) =>
                                handleAnswerClick(
                                  selectedRow._id,
                                  selectedRow.email,
                                  event
                                )
                              }
                              className={
                                theme.palette.mode === "dark"
                                  ? "dark-menu-item-btn"
                                  : "light-menu-item-btn"
                              }
                            >
                              Answer
                            </a>
                          </MenuItem>
                          <MenuItem>
                            <a
                              href="#"
                              onClick={() =>
                                handleOpen(selectedRow._id, "compliance")
                              }
                              className={
                                theme.palette.mode === "dark"
                                  ? "dark-menu-item-btn"
                                  : "light-menu-item-btn"
                              }
                            >
                              Remove
                            </a>
                          </MenuItem>
                        </Menu>
                      )}
                    </Box>
                  </TabPanel>

                  <TabPanel value="5">
                    <div className="compliance-inline">
                      <h2>Remediation</h2>
                      {/* <Link to={`../user-remediation-information/${vendorUrl}`}>
                        <Button
                          variant="contained"
                          className="addnew send-assessment"
                        >
                          Send Remediation{" "}
                        </Button>
                      </Link> */}
                    </div>
                    {remediationData.length > 0 ? (
                      <div className="Assessment-box">
                        {remediationData.map((key, val) => (
                          <>
                            <Grid
                              container
                              columns={0}
                              columnSpacing={3}
                              className="box-Assessment"
                            >
                              <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'left' }}>
                                <p><b>Q:</b> <span>{key?.remediationPlan?.que?.text ? key?.remediationPlan?.que?.text : 'N/A'}</span></p>
                              </Grid>
                              <Grid item xs={12} md={2} lg={2}>
                                <div className="widthboxes">
                                  <h5>Vendor</h5>
                                  <p>{key.firstName}</p>
                                </div>
                              </Grid>
                              <Grid item xs={6} md={4} lg={4}>
                                <div className="widthboxes">
                                  <h5>Email</h5>
                                  <p>{key.email}</p>
                                </div>
                              </Grid>
                              <Grid item xs={12} md={2} lg={2}>
                                <div className="widthboxes">
                                  <h5>URL</h5>
                                  <p>{key.url}</p>
                                </div>
                              </Grid>
                              <Grid item xs={12} md={2} lg={2}>
                                <div className="widthboxes">
                                  <h5>Created Date</h5>
                                  <p>
                                    {moment(
                                      new Date(key.startDate * 1000)
                                    ).format("MMMM Do YYYY")}
                                  </p>
                                </div>
                              </Grid>
                              <Grid item xs={12} md={2} lg={2}>
                                <div className="widthboxes">
                                  <h5>Action</h5>
                                  <div className="icons-aligns">
                                    {/* <DeleteIcon color="#4a10a4" onClick = {(e) => deleteRemediation(key.id)}/> */}
                                    {/* <a href="#" className="remove-color"  onClick = {(e) => deleteRemediation(key.id)}>Remove</a> */}
                                    <a
                                      href="#"
                                      className="remove-color"
                                      onClick={() =>
                                        handleOpen(key.id, "remediation")
                                      }
                                    >
                                      Remove
                                    </a>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </>
                        ))}
                      </div>
                    ) : (
                      <div className="Assessment-box">
                        <div className="manage-mid">
                          <h2>No Remediation Found!</h2>
                          {/* <Link
                            to={`../user-remediation-information/${vendorUrl}`}
                          >
                            <Button
                              variant="contained"
                              className="addnew click-to-begin"
                            >
                              Click to Begin{" "}
                            </Button>
                          </Link> */}
                        </div>
                      </div>
                    )}
                  </TabPanel>

                  <TabPanel value="6">

                    <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
                      <Grid item>
                        <Typography variant="h4" gutterBottom>
                          <span className="heading-main">{showDocumentEvidence}</span>
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Stack direction="row" spacing={2} alignItems="center">
                          {showDocumentEvidence === "Documents" && (
                            <Button
                              variant="contained"
                              startIcon={<Iconify icon="eva:plus-fill" />}
                              onClick={() => { handleModelOpen(); }}
                            >
                              Request/Upload Documents
                            </Button>
                          )}
                          <FormControl sx={{ width: '200px' }}>
                            <Select
                              labelId="demo-simple-select-autowidth-label"
                              id="demo-simple-select-autowidth"
                              value={showDocumentEvidence}
                              onChange={handleDocumentorEvidenceChange}
                              sx={{ height: '40px', padding: '4px' }} // Adjusted height and padding for Select

                            >
                              <MenuItem key="Documents" value="Documents">
                                Documents
                              </MenuItem>
                              <MenuItem key="Evidences" value="Evidences">
                                Evidences
                              </MenuItem>
                            </Select>
                          </FormControl>

                        </Stack>
                      </Grid>
                    </Grid>

                    {showDocumentEvidence === "Documents" ? (
                      <div >
                        <div className="model" style={{ marginBottom: '20px' }}>

                          <Modal
                            open={modelOpen}
                            onClose={() => { handleModelCloseFunction() }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            className={
                              theme == "Dark"
                                ? "dark-attack-modal modal-popup"
                                : "light-attack-modal modal-popup"
                            }
                          >
                            <Box sx={style} className="black-bg">
                              <TabContext value={documentValue}> {/* Use a separate TabContext for nested tabs */}
                                <TabList
                                  onChange={handleDocumentRequestChange}
                                  variant="scrollable"
                                  allowScrollButtonsMobile
                                  aria-label="lab API tabs example"
                                >
                                  <Tab label="Request" value="11" />
                                  <Tab label="Upload" value="22" />
                                </TabList>

                                <TabPanel value="11">
                                  <VendorRequestDocuments
                                    handleModelCloseFunction={() => handleModelCloseFunction()}
                                    vendorNotAvailableDocuments={vendorNotAvailableDocuments}
                                    vendorUrl={vendorUrl}
                                    getVendorDocuments={() => getVendorDocuments()}
                                    vendorDocumentsTypes={() => vendorDocumentsTypes}
                                    contactDetails={contactDetails}
                                  />

                                </TabPanel>

                                <TabPanel value="22">
                                  <VendorUploadDocuments
                                    vendorNotAvailableDocuments={vendorNotAvailableDocuments}
                                    vendorUrl={vendorUrl}
                                    handleModelCloseFunction={() => handleModelCloseFunction()}
                                    getVendorDocuments={() => getVendorDocuments()}
                                  />
                                </TabPanel>

                              </TabContext>
                            </Box>

                          </Modal>
                        </div>

                        <Box
                          className="bg-dark-assessment"
                          sx={{
                            width: "100%",
                            backgroundColor: "#fff",
                            border: "none",
                            borderRadius: "15px",
                          }}
                        >
                          <DataGrid
                            sx={{ border: "none", width: "100%", height: "400px" }}
                            rows={vendorAvailableDocuments}
                            getRowId={(row) => row._id} // Use the custom ID for each row
                            columns={centralizedRepoColumns}
                            components={{
                              NoRowsOverlay: () => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    textAlign: "center",
                                    fontSize: "18px",
                                  }}
                                >
                                  No data
                                </Box>
                              ),
                            }}
                            initialState={{
                              pagination: {
                                paginationModel: {
                                  pageSize: 5,
                                },
                              },
                              filter: {
                                filterModel: {
                                  items: [],
                                  quickFilterValues: [],
                                },
                              },
                            }}
                            autosizeOptions={{
                              columns: ["id", "firstName", "lastName"],
                              includeOutliers: true,
                              includeHeaders: false,
                            }}
                            pageSizeOptions={[5, 10, 25]}
                            slots={{
                              toolbar: GridToolbar,
                              noRowsOverlay: CustomNoRowsOverlay,
                            }}
                            slotProps={{
                              toolbar: {
                                showQuickFilter: true,
                                printOptions: { disableToolbarButton: true },
                                csvOptions: { disableToolbarButton: true },
                              },
                            }}
                            disableRowSelectionOnClick
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            disableColumnMenu
                          />
                          {/* {selectedRow && ( 
                          <Menu
                            id="actions-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem>
                              <a
                                // href={`../security-control/${selectedRow._id}`}
                                onClick={() => navigate(`../security-control/${selectedRow._id}`, { state: `vendorDetails/${vendorUrl}` })}
                                className={
                                  theme.palette.mode === "dark"
                                    ? "dark-menu-item-btn"
                                    : "light-menu-item-btn"
                                }
                              >
                                Security
                              </a>
                            </MenuItem>
                            <MenuItem>
                              <a
                                href="#"
                                onClick={(event) =>
                                  handleAnswerClick(
                                    selectedRow._id,
                                    selectedRow.email,
                                    event
                                  )
                                }
                                className={
                                  theme.palette.mode === "dark"
                                    ? "dark-menu-item-btn"
                                    : "light-menu-item-btn"
                                }
                              >
                                Answer
                              </a>
                            </MenuItem>
                            <MenuItem>
                              <a
                                href="#"
                                onClick={() =>
                                  handleOpen(selectedRow._id, "compliance")
                                }
                                className={
                                  theme.palette.mode === "dark"
                                    ? "dark-menu-item-btn"
                                    : "light-menu-item-btn"
                                }
                              >
                                Remove
                              </a>
                            </MenuItem>
                          </Menu>
                        )}*/}
                        </Box>
                      </div>
                    ) : (
                      <div>
                        <Box
                          className="bg-dark-assessment"
                          sx={{
                            width: "100%",
                            backgroundColor: "#fff",
                            border: "none",
                            borderRadius: "15px",
                          }}
                        >
                          <DataGrid
                            sx={{ border: "none", width: "100%", height: "400px" }}
                            rows={vendorQuestionnaireEvidenceData}
                            getRowId={(row) => row.id} // Use the custom ID for each row
                            columns={vendorQuestionnaireEvidenceColumn}
                            components={{
                              NoRowsOverlay: () => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    textAlign: "center",
                                    fontSize: "18px",
                                  }}
                                >
                                  No data
                                </Box>
                              ),
                            }}
                            initialState={{
                              pagination: {
                                paginationModel: {
                                  pageSize: 5,
                                },
                              },
                              filter: {
                                filterModel: {
                                  items: [],
                                  quickFilterValues: [],
                                },
                              },
                            }}
                            autosizeOptions={{
                              columns: ["id", "firstName", "lastName"],
                              includeOutliers: true,
                              includeHeaders: false,
                            }}
                            pageSizeOptions={[5, 10, 25]}
                            slots={{
                              toolbar: GridToolbar,
                              noRowsOverlay: CustomNoRowsOverlay,
                            }}
                            slotProps={{
                              toolbar: {
                                showQuickFilter: true,
                                printOptions: { disableToolbarButton: true },
                                csvOptions: { disableToolbarButton: true },
                              },
                            }}
                            disableRowSelectionOnClick
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            disableColumnMenu
                          />
                        </Box>
                      </div>
                    )}

                  </TabPanel>
                  <TabPanel value="7">

                    <FaildeControl />

                  </TabPanel>

                </>
              ) : null}
            </TabContext>
          </Box>
        )}
        {error && (
          <Box sx={{ display: "flex" }}>
            <Grid>
              <Card item xs={12} md={6} lg={6} sx={{ p: 5 }}>
                <h4>No Record for this url</h4>
              </Card>
            </Grid>
          </Box>
        )}
        <ModalDialog
          handleClose={handleClose}
          open={openRemediation}
          onSubmit={deleteRemediation}
          contentData={ModalContentRemediation}
        />
        <ModalDialog
          handleClose={handleClose}
          open={openAssessment}
          onSubmit={deleteAssessment}
          contentData={ModalContentAssessment()}
        />
        <Drawer
          sx={{ transition: "none", boxShadow: "none" }}
          anchor={"right"}
          open={state["right"]}
          onBackdropClick={() => {
            setState({ ...state, right: false });
          }}
        >
          {list("right", activeIssue)}
        </Drawer>
      </Container>
    </>
  );
}
