import { Box, Button, Card, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react'
import { useSnackbar } from "notistack";
import ClearIcon from "@mui/icons-material/Clear";
import PostRequest from '../components/apiConnections/postRequest';
import DeleteIcon from '@material-ui/icons/Delete';
import Loader from '../Loader';

function VendorUploadDocuments({
    vendorUrl,
    handleModelCloseFunction,
    vendorNotAvailableDocuments,
    getVendorDocuments
}) {

    const [showLoader, setshowLoader] = useState(false);
    const [errors, setErrors] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const [formData, setFormData] = useState({
        documents: [{ documentType: '', isRequest: false, documentFile: null, documentName: null }],
    });
    let availableDocumentTypes;
    const handleRequestDocuments = async () => {
        setshowLoader(true);
        if (validateFields()) {
            let requestData = new FormData();
            const vendorDocument = formData.documents
                .map(doc => ({
                    documentName: doc.documentFile.name,
                    vendorDomain: vendorUrl,
                    documentType: doc.documentType._id
                }));

            for (let item of formData.documents) {
                if (item.documentFile) {
                    requestData.append("vendorDocumentFiles", item.documentFile);
                }
            }
            requestData.append("vendorDocument", JSON.stringify(vendorDocument));

            try {
                const refreshToken = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                    {},
                    {},
                    "refreshToken"
                );
                if (refreshToken) {
                    PostRequest(
                        // `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${"upload-document-of-vendor"}`,
                        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOAD_DOCUMENT_OF_VENDOR}`,
                        requestData)
                        .then((response) => {
                            enqueueSnackbar(response.data.message, { variant: "success" });
                            getVendorDocuments();
                            handleModelCloseFunction();
                            setshowLoader(false);
                        })
                        .catch((errApi) => {
                            enqueueSnackbar(errApi.data.message, { variant: "error" });
                            setshowLoader(false);
                        })
                }
            } catch (error) {
                // no data
                enqueueSnackbar("Requested Url not find", { variant: "error" });
                handleModelCloseFunction();
                setshowLoader(false);
                return false;
            }
            // handleModelCloseFunction();
        }
        else {
            enqueueSnackbar("Please provide valid details", { variant: 'error' });
            setshowLoader(false);
        }
    }
    const validateFields = () => {
        const newErrors = {};

        // Validate documents
        formData.documents.forEach((document, index) => {
            console.log(document)
            if (!document.documentType) {
                newErrors[`document-${index}-type`] = '*Please select a document type';
            }
            if (!document.documentFile) {
                newErrors[`document-${index}-file`] = '*Please upload a document file';
            }
        });

        setErrors(newErrors);
        console.log(Object.keys(newErrors).length === 0);
        return Object.keys(newErrors).length === 0;
    };

    const handleDeletedOC = (index) => {
        const updatedDOC = formData.documents.filter((_, i) => i !== index);
        setFormData({ ...formData, documents: updatedDOC });
    };

    const validateFileType = (file) => {
        const validFileTypes = [
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
            'application/vnd.ms-excel' // .xls
        ];

        return file ? validFileTypes.includes(file.type) : false;
    };

    const handleFileChange = (e, index) => {
        const file = e.target.files[0];

        if (validateFileType(file)) {
            const updatedDocuments = [...formData.documents];
            updatedDocuments[index].documentFile = file;
            setFormData((prev) => ({ ...prev, documents: updatedDocuments }));

            // Clear error if previously set
            setErrors((prevErrors) => ({
                ...prevErrors,
                [`document-${index}-file`]: ''
            }));
        } else {
            // Reset the file input
            e.target.value = '';

            // Set error message for invalid file type
            setErrors((prevErrors) => ({
                ...prevErrors,
                [`document-${index}-file`]: 'Only PDF or Excel files are allowed'
            }));
        }
    };

    return (
        <div>
            <Loader show={showLoader} />
            <Card sx={{ p: 2, m: 0, width: "100%" }} className="atta    ckpart">
                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <ClearIcon
                                className="cross-icon"
                                onClick={() => { handleModelCloseFunction() }}
                            ></ClearIcon>
                            <FormControl sx={{ width: "100%" }}>
                                <Box>
                                    <Grid item xs={12}>
                                        {formData.documents.map((document, index) => {
                                            availableDocumentTypes = vendorNotAvailableDocuments?.filter(
                                                (type) => !formData.documents.some((doc, i) => doc.documentType?._id === type._id && i !== index)
                                            );
                                            return (
                                                <Grid container spacing={2} key={index} alignItems="center">
                                                    <Grid item xs={12} md={4}>
                                                        <FormControl fullWidth sx={{ mb: 2 }}>
                                                            <InputLabel id={`doc-type-label-${index}`}
                                                                shrink={document.documentType !== ""}
                                                                sx={{
                                                                    '&.Mui-focused': {
                                                                        color: document.documentType ? 'primary.main' : 'rgba(255, 255, 255, 0.7)',
                                                                    },
                                                                }}>Select Document Type</InputLabel>
                                                            <Select
                                                                labelId={`doc-type-label-${index}`}
                                                                id={`doc-type-${index}`}
                                                                name="documentType"
                                                                value={document.documentType || ''}
                                                                onChange={(e) => {
                                                                    const updatedDocuments = [...formData.documents];
                                                                    updatedDocuments[index].documentType = e.target.value;
                                                                    setFormData((prev) => ({ ...prev, documents: updatedDocuments }));
                                                                }}
                                                                required
                                                                label="Upload Document Type"
                                                                error={!!errors[`document-${index}-name`]}
                                                                helperText={errors[`document-${index}-name`]}
                                                            >
                                                                {availableDocumentTypes?.length > 0 ? (
                                                                    availableDocumentTypes.map((type) => (
                                                                        <MenuItem key={type._id} value={type}>
                                                                            {type.name}
                                                                        </MenuItem>
                                                                    ))
                                                                ) : (
                                                                    <MenuItem value="" disabled>
                                                                        Not Available
                                                                    </MenuItem>
                                                                )
                                                                }
                                                            </Select>
                                                            {!!errors[`document-${index}-type`] && (
                                                                <FormHelperText sx={{ color: 'red' }} >{errors[`document-${index}-type`]}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                                    </Grid>

                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            type="file"
                                                            name="documentFile"
                                                            inputProps={{ accept: ".pdf, .xlsx, .xls" }}
                                                            onChange={(e) => handleFileChange(e, index)}
                                                            fullWidth
                                                            error={!!errors[`document-${index}-file`]}
                                                            helperText={errors[`document-${index}-file`]}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={1}>
                                                        {index > 0 && (
                                                            <DeleteIcon
                                                                className="delete-icon-document"
                                                                onClick={() => handleDeletedOC(index)}
                                                                sx={{ cursor: 'pointer', color: 'red' }}
                                                            />
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                        {availableDocumentTypes && availableDocumentTypes?.length > 1 && (
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                onClick={() => {
                                                    const updatedDocuments = [...formData.documents, { documentType: '', documentFile: null, documentName: null }];
                                                    setFormData((prev) => ({ ...prev, documents: updatedDocuments }));
                                                }}
                                                sx={{ mt: 2 }}
                                            >
                                                Add Another Document
                                            </Button>
                                        )}
                                    </Grid>
                                </Box>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>

                        <Grid item xs={12}>
                            {/* <Divider sx={{ my: 2 }} /> */}
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={handleRequestDocuments}
                            // disabled={!contactPerson  || 
                            //   formData.documents.every(doc => 
                            //   doc.documentType === '' && 
                            //   !doc.isUpload && 
                            //   doc.documentFile === null && 
                            //   doc.documentName === null
                            // )}

                            >
                                Upload Document
                            </Button>
                        </Grid>

                    </Grid>
                </Box>
            </Card>
        </div>
    )
}

export default VendorUploadDocuments