import React from 'react'
import {
  Button, Typography,
  Box,
  Modal,
} from '@mui/material';
import './css/questionnaire.css';
import QuestionCategoryInfo from './QuestionCategoryInfo';


// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function QuestionnairePreview({ onClose, sections, title, description, submit, showSubmit, open, handleClose }) {
  console.log(sections)
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="tabs-bg preview-popup" sx={{ bgcolor: 'background.paper' }} >
        <div className='relative-popup'>
          <div className='preview-page'>Preview</div>
          {showSubmit ?    <div className='preview-submission'>
            <h2>This is a preview of your submission. It has not been submit yet</h2>
            <p>Please take a moment to verify your information. You can also go back to make changes</p>
          </div> :null}
          <div className="title-preview">
            <h2>Title:</h2>
            <p>{title}</p>
          </div>
          <div className="title-preview">
            <h2>Description:</h2>
            <p>{description}</p>
          </div>
          {Array.isArray(sections) ? sections.map((item, i) => {
            return (
              <>
                <div className="title-preview">
                  <h2>Section Name:</h2>
                  <p>{item.category}</p>
                </div>
                {item.questions ? item.questions?.map((question, j) => {
                  return (
                    <div className="title-preview">
                      <h2>Question no {j + 1}</h2>
                      <div>
                        <p>{question.text}</p>
                        <QuestionCategoryInfo 
                           data={question}
                            />
                        {question.options ? question.options?.map((option, k) => {
                          return (
                            <>
                              <div className="inline-form-check checkbox-outline preview">
                                <label className="custom-checkbox">
                                  {question.optionType === "MCQ" ? <input type="checkbox" id={`${j + 1}-flexCheckDefault${k + 1}`} checked={false} /> :
                                    <input type="radio" id={`${j + 1}-flexRadioDefault${k + 1}`} checked={false} />}
                                  <span className="checkmark"></span>
                                  <span className="assessment-datacheck">{option.title}</span>
                                </label>
                              </div>
                            </>
                          )
                        }) : null}
                      </div>
                    </div>
                  )
                }) : null}
              </>
            )
          }) : null}


          <div className='previous-submit'>
            <Button variant="contained" className='addsection back-to-previous' onClick={handleClose}>
              Back to Previous
            </Button>
            {showSubmit ? <Button variant="contained" className='addsection' onClick={submit}>
              Submit
            </Button> : null}
          </div>

          {/* {description ? <Box className="preview-pop-heading">{description}</Box> : null}
          <div className='header-sticky'>
            <Button onClick={onClose} className='cross-right-side'>
              <CloseIcon />
            </Button>
          </div> */}
          {/* <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
            <Tabs
              orientation="vertical"
              // variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: 'divider' }}
            >
              {sections ? sections.map((data, i) => {
                return <Tab key={i} label={data.title} {...a11yProps(i)} />
              }) : null}
            </Tabs>
            <div className='tabpanel-full-size'>
              {sections ? sections.map((data2, i) => {
                return (
                  <TabPanel key={i} value={value} index={i} className="tabpanel-full">
                    <div className="question-part-preview">
                      <div className="inline-parts">
                        <h3>Questions</h3>
                      </div>
                      {data2.questions ? data2.questions?.map((data3, j) => {
                        return (
                          <div key={j} className='question-card'>
                            <div className='flex-preview-tab'>
                              <p className='preview-question-heading'>Q {j + 1}  </p>
                              <span className='data-text-preview'>{data3.text}</span>
                            </div>
                           <QuestionCategoryInfo 
                           data={data3}
                           marginLeftTrue2={true}
                            />
            
                            {data3.options ? data3.options?.map((data4, k) => {
                              return (
                                <div key={k} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div style={{marginLeft: '48px'}}>
                                    <span className='preview-question-heading'> {(data3.type || data3?.optionType) === 'MCQ' ?
                                      <Checkbox checked={false} /> : <Radio checked={false} />
                                    } </span>
                                    <span className='data-text-preview'>{data4.title}</span>
                                  </div>
                     
                                </div>
                              )
                            }) : null}
                          </div>
                        )
                      }) : null}
                    </div>
                  </TabPanel>
                )
              }) : null}
              {showSubmit ? <Button variant="contained" className="addnew export-btn" onClick={submit}>Submit</Button> : null}
            </div>
          </Box> */}
          {/*<TabPanel value={value} index={1} className="tabpanel-full">
            Item Two
          </TabPanel>
          <TabPanel value={value} index={2} className="tabpanel-full">
            Item Three
          </TabPanel> */}
        </div>
      </Box>
    </Modal>

  )
}

export default QuestionnairePreview