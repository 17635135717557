import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import CrossSelect from '../../assets/cross-select.svg';
import CalendarTodayIcon from '../../assets/calender-icon-select.svg';
import { Grid, CircularProgress, TextField, Typography,InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function ExtendDeadlineModel({
    handleClose,
    open,
    contentData,
    onSubmit,
    deadline,
    loader,
    handleExtendDeadlineChange,
    getCurrentDate
}) {
    const [theme, setTheme] = useState(localStorage.getItem("theme"));
    const [content, setContent] = useState(contentData);
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        // width: 600,
        // bgcolor: "background.paper",
        // boxShadow: 24,
        textAlign: "center",
        borderRadius: "10px",
        p: 4,
    };
    useEffect(() => {
        setInterval(() => {
            const themeName =
                localStorage.getItem("theme") !== null
                    ? localStorage.getItem("theme")
                    : "Dark";
            setTheme(themeName);
        }, 2000);
    }, []);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={
                    theme === "Dark" ? "dark-modal modal-popup" : "light-modal modal-popup"
                }
            >
                <Box sx={style} className="select-vendor-popup">
                   <div className='select-assessment-heading'>
                        <Typography>
                          Extend Deadline
                        </Typography>
                        <img src={CrossSelect} alt='icon'/>
                    </div>
                    <div className='padding-form-assessment topspace-extend'>
                       <Typography id="modal-modal-description">
                         <form onSubmit={onSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField
                                        name="deadline"
                                        type="date"
                                        label="Deadline"
                                        className="deadline-input"
                                        value={deadline}
                                        InputProps={{
                                            readOnly: true,
                                            style: { color: 'white !important',}
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                  <TextField
                                    name="extendDeadline"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    type="date"
                                    label="Extend Deadline"
                                    className="deadline-input"
                                    onChange={handleExtendDeadlineChange}
                                    inputProps={{
                                        min: getCurrentDate(new Date()),
                                        onKeyDown: (e) => e.preventDefault(),
                                        // Remove the default calendar icon from the input field
                                        style: {
                                        WebkitAppearance: 'none', // Remove on Chrome/Safari/Edge
                                        MozAppearance: 'textfield', // Remove on Firefox
                                        appearance: 'none', // General appearance removal
                                        position: 'relative',
                                        },
                                    }}
                                    sx={{ color: "white" }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                            {/* {/ Custom image for opening the date picker /} */}
                                            <img
                                              src={CalendarTodayIcon}
                                              alt="calendar icon"
                                              style={{ cursor: 'pointer' }}							 
                                              onClick={() => {
                                                document.getElementsByName('extendDeadline')[0].showPicker();
                                              }}
                                            />
                                          </InputAdornment>
                                        ),
                                    }}
                                    />
                                </Grid>
                             </Grid>
                         </form>
                         </Typography>
                     
                    <Grid item xs={12} md={12} lg={12} className="center-vendor">
                        <Button
                            variant="contained"
                            className="click-to-begin model-btns cancel-popups-btn cancel-as-well"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <LoadingButton loading={loader}
                            loadingIndicator={<CircularProgress size={12} sx={{ marginRight: "85px", color: "white" }} />}
                            size="large"
                            type="submit"
                            onClick={() => onSubmit()}
                            variant="contained" className="cancel-popups-btn" >
                            <span>Confirm</span>
                        </LoadingButton>
                        </Grid>
                     </div>
                </Box>
            </Modal>
        </div>
    );
}
