import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "./css/Vendors.css";
// @mui
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  styled,
  Typography,

} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
// components
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSnackbar } from "notistack";
import Delete from "../assets/delete.svg";
import PostRequest from "../components/apiConnections/postRequest";
import UpIcon from '../assets/up-icon.svg';
import DownIcon from '../assets/down-arrow.svg';
import DatagridVendor from "./DatagridVendor.js";
import Approval from "./VenderApproval.js";

import ModalDialog from "./ModalDialog.js";
import NewHeader from "../layouts/dashboard/header/NewHeader.js";
import GetRequest from "../components/apiConnections/getRequest.js";
const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));
/*Semi Chart*/
const donutoptions = {
  chart: {
    height: 100,
    type: "radialBar",
  },
  plotOptions: {
    radialBar: {
      startAngle: -140,
      endAngle: 140,
      hollow: {
        size: "65%",
      },
      track: {
        background: "#332961", // Track background color
        strokeWidth: "100%", // Track thickness
        margin: 0, // Space between bar and track
      },

      dataLabels: {
        name: {
          show: true,
          fontSize: "16px",
        },
        value: {
          show: true,
          fontSize: "16px",
          color: "#fff",
          offsetY: -10, // Center the value vertically
          formatter: function (val) {
            return val + "";
          },
        },
      },
      // Enable rounded corners
      rounded: false, // Enable rounded corners
    },
  },
  colors: ["#533460"], // Single color for the segment
  stroke: {
    lineCap: "",
  },

  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "horizontal",
      shadeIntensity: 0.5,
      gradientToColors: ["#E6171D"],
      inverseColors: false,
      stops: [0, 100],
    },
  },
  labels: [""],
};

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Ecosystems Found</Box>
    </StyledGridOverlay>
  );
}

export default function UserPage() {
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setshowLoader] = useState(false);
  const [username, setUserName] = useState(
    window.localStorage.getItem("userEmail")
  );
  const [openUser, setOpenUser] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [userRemoveId, setUserRemoveId] = useState(0);
  const [adminRemoveId, setAdminRemoveId] = useState(0);
  const roleId = parseInt(window.localStorage.getItem("roleId"));
  const [approvalData, setApprovalData] = useState([])
  const [ecosystemData, setEcosystemData] = useState([])
  const [value, setValue] = useState(0);  // Tabs sCorrect usage inside the component
  const [thirdPartyRiskLen, setthirdPartyRiskLen] = useState(0);
  const [thirdPartyRiskLoader, setThirdPartyRiskLoader] = useState(false);
  const [thirdPartyRisk, setThirdPartyRisk] = useState([]);
  const [chartSeries, setChartSeries] = useState([0, 0, 0, 0]);
  const [assessment25, setAssessment25] = useState(0);
  const [assessment50, setAssessment50] = useState(0);
  const [assessment75, setAssessment75] = useState(0);
  const [assessment100, setAssessment100] = useState(0);
  const [assessmentLoader, setAssessmentLoader] = useState(false);
  const [totalAssessment, setTotalAssessments] = useState(0);
  const [failedControl, setTotalFailedControl] = useState(0);
  const [prevFailedControl, setPrevFailedControl] = useState(0);
  const [totalPendingRemediation, setTotalPendingRemediation] = useState(0);
  const [totalFailedControlLoader, setTotalFailedControlLoader] = useState(false);
  const [totalPendingRemediationLoader, setTotalPendingRemediationLoader] = useState(false);

  const handleChange = (event, newValue) => { // Tabs
    setValue(newValue);
  };
  const [isColumnHidden, setIsColumnHidden] = useState(true);


  const [columns, setColumns] = useState([
    { field: "id", headerName: "No.", editable: false, maxWidth: 80 },
    {
      field: "name",
      headerName: "Name",
      editable: false,
      minWidth: 180,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "target",
      headerName: "Target",
      editable: false,
      width: 200,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "vendorType",
      headerName: "Type",
      editable: false,
      maxWidth: 100,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "score",
      headerName: "Score",
      editable: false,
      maxWidth: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "risk",
      headerName: "Risk",
      editable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortComparator: (v1, v2, param1, param2) => {
        return param1.api?.getRow(param1.id)?.score - param2.api?.getRow(param2.id)?.score
      },
      renderCell: (params) => {
        const score = params.row.score;
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "white",
              borderRadius: "3px",
              paddingY: "5px",
              paddingX: "15px",
              backgroundColor: `${score == "-" ? null :
                score <= 25
                  ? "red"
                  : score <= 50
                    ? "#ff7300"
                    : score <= 75
                      ? "#f9a825"
                      : "green"
                }`,
            }}
          >
            {score == "-" ? "-" :
              score <= 25
                ? "Critical"
                : score <= 50
                  ? "High"
                  : score <= 75
                    ? "Medium"
                    : "Low"}
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const status = params.row.status;
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "white",
              borderRadius: "3px",
              paddingY: "5px",
              paddingX: "15px",
              backgroundColor: `${status == "Awaiting"
                ? "red"
                : "green"
                }`,
            }}
          >
            {status}
          </Box>
        );
      },
    },
    {
      field: "vendorCurrentStatus",
      headerName: "Onboarding Status",
      editable: false,
      maxWidth: 100,
      headerAlign: "left",
      align: "left",
      hidden: isColumnHidden
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className=" icons-aligns-new icons-aligns  add-questionnaire-action mui-icons-questionnaire">
            <Link
              to={`/${roleId === 2 ? "admin" : "dashboard"}/vendorDetails/${params.row.target
                }`}
            >
              <Button>
                <VisibilityIcon />
              </Button>
            </Link>
            <Button
              onClick={() => {
                handleOpen(params.row.target, roleId === 2 ? "admin" : "user");
              }}
            >
              <DeleteIcon />
            </Button>
          </Box>
        );
      },
    },
  ]);

  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "asc",
    },
  ]);

  useEffect(() => {
    setOpenUser(userRemoveId !== 0 ? true : false);
    getThirdPartyAtRiskData();
    getScoreBasedAssessmentCount();
    getTotalFailedControl();
    getTotalPendingRemediation();
  }, [userRemoveId]);

  useEffect(() => {
    getThirdPartyAtRiskData();
    setOpenAdmin(adminRemoveId !== 0 ? true : false);
  }, [adminRemoveId]);
  /*First chart*/
  const chartOptions = {
    chart: {
      type: 'donut',
      dropShadow: {
        enabled: true,
        color: '#000', // Shadow color
        top: 2, // Vertical offset
        left: 0, // Horizontal offset
        blur: 3, // Blur radius
        opacity: 0.25 // Opacity of the shadow
      }
    },
    labels: ['Critical', 'High', 'Medium', 'Low'],
    colors: ['#CC2121', '#BA5705', '#BFB608', '#3C851F'],
    stroke: {
      show: false, // Disable the outline/stroke
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    plotOptions: {
      pie: {
        donut: {
          size: '78%',
          labels: {
            show: true,
            name: {
              show: true,
              offsetY: 30,  // Move the name (category) label down
            },
            value: {
              show: true,
              fontSize: '16px',
              fontFamily: 'Mona-Sans, sans-serif',
              color: '#fff',
              offsetY: -15,

            },
            total: {
              show: true,
              label: 'Total',
              fontSize: '16px',
              color: '#fff',
              fontFamily: 'Mona-Sans, sans-serif',
              formatter: () => {
                return thirdPartyRiskLen;
              },
              style: {
                fontFamily: 'Mona-Sans, sans-serif',
                fontSize: '28px',
                color: '#fff',
              },
              offsetY: 50, // Also ensure the total label is moved below
            },
          },

        }
      }
    },
    dataLabels: {
      enabled: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '13px',
        fontFamily: 'Mona-Sans, sans-serif',
        colors: ['#fff']
      }
    },
    legend: {
      position: 'right',
      offsetY: 0,
      height: 150,
      markers: {
        width: 11,           // Width of the square marker
        height: 12,          // Height of the square marker
        shape: 'square',     // Set the shape to square
        radius: 0,           // No border-radius for square
        offsetX: -5,
      }
    }
  };

  const options = {
    chart: {
      type: "bar",
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 0,
        left: 0,
        blur: 4,
        opacity: 0.25,
      },
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '15.60px',
        colors: {
          hover: {
            shade: 'light',
            shadeIntensity: 0.5
          }
        }
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [""],
      labels: {
        show: false,
        style: {
          colors: '#000' // Font color for x-axis labels
        }
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
      },
    },
    colors: ["#CC2121", "#BA5705", "#BFB608", "#3A821D"],
    legend: {
      offsetX: -28,
      position: 'right',
      offsetY: -10,
      height: 155,
      markers: {
        width: 11,
        height: 12,
        shape: 'square',
        radius: 0,
        offsetX: -5,
      },
      labels: {
        colors: '#000' // Font color for legend labels
      }
    },
    tooltip: {
      theme: 'dark', // Use 'dark' theme to start with
      style: {
        fontSize: '10px',
        colors: ['#000'] // Set tooltip text color to black
      }
    }
  };

  const series = [
    {
      name: "0-25",
      data: [assessment25],
    },
    {
      name: "25-50",
      data: [assessment50],
    },
    {
      name: "50-75",
      data: [assessment75],
    },
    {
      name: "75-100",
      data: [assessment100],
    },
  ];
  const nullDataOptions = {
    chart: {
      type: "bar",
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 2,
        left: 0,
        blur: 3,
        opacity: 0.25
      },
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '15.60px',
        colors: {
          hover: {
            shade: '#292154',
            shadeIntensity: 0.5
          }
        }
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [""],
      labels: {
        show: false,
        style: {
          colors: '#292154' // Font color for x-axis labels
        }
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
      },
    },
    colors: ["#292154"],
    legend: {
      offsetX: -28,
      position: 'right',
      offsetY: -10,
      height: 155,
      markers: {
        width: 11,
        height: 12,
        shape: 'square',
        radius: 0,
        offsetX: -5,
      },
      labels: {
        colors: '#fff' // Font color for legend labels
      }
    },
    tooltip: {
      theme: '#fff', // Use 'dark' theme to start with
      style: {
        fontSize: '10px',
        colors: ['#000'] // Set tooltip text color to black
      }
    }
  };
  const isChartSeriesNullDonut = chartSeries.every(value => value === 0);
  const getEmptyDonutOptions = (baseOptions) => ({
    ...baseOptions,
    colors: ['#292154', '#292154', '#292154', '#292154'], // Light color to show "empty" donut
    plotOptions: {
      pie: {
        donut: {
          ...baseOptions.plotOptions.pie.donut,
          labels: {
            show: true,
            total: {
              show: true,
              label: 'No Data',
              fontSize: '16px',
              color: '#fff', // Muted color for "No Data" text
              fontFamily: 'Mona-Sans, sans-serif',
              style: {
                fontFamily: 'Mona-Sans, sans-serif',
                fontSize: '28px',
                color: '#fff', // Muted color for total value
              },
              formatter: () => '0', // Show "0" for total when no data
            },
            value: {
              show: true,
              fontSize: '28x', // Font size for the total value
              fontFamily: 'Mona-Sans, sans-serif',
              color: '#fff', // Total value color
              offsetY: 20 // Moved the total value upwards
            }
          },
        }
      }
    },
    tooltip: {
      enabled: false, // Disable tooltip on empty donut
    }
  });
  const handleClose = () => {
    setOpenUser(false);
    setOpenAdmin(false);
    setUserRemoveId(0);
    setAdminRemoveId(0);
  };

  const handleOpen = (id, text) => {
    if (text === "user") {
      setUserRemoveId(id);
    } else if (text === "admin") {
      setAdminRemoveId(id);
    }
  };

  const removeData = async () => {
    const deleteId = adminRemoveId;
    setshowLoader(true);
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      {},
      "refreshToken"
    );
    if (refreshToken) {
      const endpoint = "delete-vendor";
      const payload = {
        vendor: deleteId,
      };
      await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
        payload
      )
        .then((resVendors) => {
          enqueueSnackbar(resVendors.data.message, { variant: "success" });
          setshowLoader(false);
          handleClose();
          setTimeout(() => {
            // getVendorDetails();
          }, 1000);
        })
        .catch((errVendor) => {
          enqueueSnackbar(errVendor.message, { variant: "error" });
          setshowLoader(false);
        });
    }
  };

  const removeLink = async () => {
    const deleteId = userRemoveId;
    setshowLoader(true);
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      {},
      "refreshToken"
    );
    if (refreshToken) {
      const endpoint = "unlinked-vendor";
      const payload = {
        username,
        vendor: deleteId,
      };
      await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
        payload
      )
        .then((resVendors) => {
          enqueueSnackbar(resVendors.data.message, { variant: "success" });
          setshowLoader(false);
          handleClose();
          setTimeout(() => {
            // getVendorDetails();
          }, 1000);
        })
        .catch((errVendor) => {
          enqueueSnackbar(errVendor.message, { variant: "error" });
          setshowLoader(false);
        });
    }
  };

  const ModalContentAdmin = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h3 className="send-assessments" style={{ whiteSpace: "nowrap" }}>
          Are you sure you want to remove vendor ?
        </h3>
      </Typography>
    );
  };

  const ModalContentUser = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h3 className="send-assessments" style={{ whiteSpace: "nowrap" }}>
          Are you sure you want to remove vendor ?
        </h3>
      </Typography>
    );
  };
  const getThirdPartyAtRiskData = async () => {
    setThirdPartyRiskLoader(true);
    try {
      const endpoint = process.env.REACT_APP_GET_THIRD_PARTY_AT_RISK;
      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
      )
        .then((resDashboard) => {
          const response = resDashboard.data.data;
          setThirdPartyRisk(response);
          setthirdPartyRiskLen(response?.length);
          let arr = [
            { vendor: "Critical", score: 0 },
            { vendor: "High", score: 0 },
            { vendor: "Medium", score: 0 },
            { vendor: "Low", score: 0 },
          ];
          if (response.length) {
            for (let item of response) {
              switch (true) {
                case item.score <= 25: arr[0].score++;
                  break;
                case item.score <= 50: arr[1].score++;
                  break;
                case item.score <= 75: arr[2].score++;
                  break;
                case item.score <= 100: arr[3].score++;
                  break;
                default:
                  break;
              }
            }
          }
          setChartSeries(arr.map(a => a.score));
          setThirdPartyRiskLoader(false);
        })
        .catch((errDashboard) => {
          enqueueSnackbar(errDashboard.message, { variant: "error" });
          setThirdPartyRiskLoader(false);
        });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setThirdPartyRiskLoader(false);
    }

  };

  const getScoreBasedAssessmentCount = async () => {
    setAssessmentLoader(true);
    try {
      const endpoint = process.env.REACT_APP_GET_SCORE_BASED_ASSESSMENT_COUNT;
      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
      )
        .then((resDashboard) => {
          const response = resDashboard?.data?.data;
          setTotalAssessments(response?.total || 0);
          setAssessment25(response?.assessment25 || 0);
          setAssessment50(response?.assessment50 || 0);
          setAssessment75(response?.assessment75 || 0);
          setAssessment100(response?.assessment100 || 0);
          setAssessmentLoader(false);
        })
        .catch((errDashboard) => {
          enqueueSnackbar(errDashboard.message, { variant: "error" });
          setAssessmentLoader(false);
        });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setAssessmentLoader(false);
    }
  };
  const getTotalFailedControl = async () => {
    setTotalFailedControlLoader(true);
    try {
      const endpoint = process.env.REACT_APP_GET_TOTAL_FAILED_CONTROLS;
      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
      )
        .then((resDashboard) => {
          const response = resDashboard.data.data;
          setTotalFailedControl(response?.failedControls ?? 0);
          setPrevFailedControl(response?.prevFailedControl ?? 0)
          setTotalFailedControlLoader(false);
        })
        .catch((errDashboard) => {
          enqueueSnackbar(errDashboard.message, { variant: "error" });
          setTotalFailedControlLoader(false);
        });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setTotalFailedControlLoader(false);
    }

  };
  const getTotalPendingRemediation = async () => {
    setTotalPendingRemediationLoader(true);
    try {
      const endpoint = process.env.REACT_APP_GET_TOTAL_PENDING_REMEDIATION;
      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
      )
        .then((resDashboard) => {
          const response = resDashboard.data.data;
          setTotalPendingRemediation(response);
          setTotalPendingRemediationLoader(false);
        })
        .catch((errDashboard) => {
          enqueueSnackbar(errDashboard.message, { variant: "error" });
          setTotalPendingRemediationLoader(false);
        });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setTotalPendingRemediationLoader(false);
    }

  };
  return (
    <>
      {/* <Loader show={showLoader} /> */}
      <Helmet>
        <title> Ecosystem | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Ecosystems" />
        <Grid container spacing={3} sx={{ mb: 3 }} className="ipad-space">
          <Grid item xs={12} md={4} className="left-fit-contain">
            <Card sx={{ mb: 1 }}>
              <div className="top-heading-dashboard donut-dashboard">
                <h2>Total Third Parties</h2>
                {!thirdPartyRiskLoader ? (<div className="total-issues-chart">
                  <Chart
                    options={isChartSeriesNullDonut ? getEmptyDonutOptions(chartOptions) : chartOptions}
                    series={isChartSeriesNullDonut ? [1] : chartSeries}
                    type="donut"
                    width="320"
                  />
                </div>) : (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: "23%" }}>
                    <CircularProgress />
                  </Box>
                )}
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} md={5} className="left-fit-contain">
            <Card sx={{ mb: 1 }}>
              <div className="top-heading-dashboard bar-ecosystem-chart">
                <h2>Assessments</h2>
                {!assessmentLoader ? (<div className="total-issues-chart bar-chart-small">
                  <h3>{totalAssessment} <span>Assessments</span></h3>
                  <Chart className="barchart-vendor" options={totalAssessment > 0 ? options : nullDataOptions} series={series} type="bar" width={400} height={175} />
                </div>) : (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: "20%" }}>
                    <CircularProgress />
                  </Box>
                )}
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ mt: 0, mb: 3 }}>
              <div className="top-heading-dashboard pending-remediation-h2">
                <h2>Pending Remediation</h2>
                <div className="pending-remediation">
                  <p>{totalPendingRemediation?.total || 0}</p>
                  {totalPendingRemediation.previousTotal > 0 &&
                    (totalPendingRemediation?.total !== totalPendingRemediation.previousTotal) ? <span className="number-up">
                    <img src={totalPendingRemediation?.total < totalPendingRemediation.previousTotal ? DownIcon : UpIcon} alt="icon" /> {
                      ((Math.abs(Number(totalPendingRemediation?.total) - Number(totalPendingRemediation.previousTotal)) * 100) / Number(totalPendingRemediation.previousTotal))?.toFixed(2)
                    }%
                  </span> : null}
                </div>
              </div>
            </Card>
            <Card>
              <div className="top-heading-dashboard pending-remediation-h2">
                <h2>Failed Controls</h2>
                <div className="pending-remediation">
                  <p>{failedControl || 0}</p>
                  {typeof failedControl == "number" && prevFailedControl > 0 && (failedControl !== prevFailedControl) ? <span className="number-up">
                    <img src={failedControl > prevFailedControl ? UpIcon : DownIcon} alt="icon" />{
                      ((Math.abs(Number(failedControl) - Number(prevFailedControl)) * 100) / Number(prevFailedControl))?.toFixed(2)
                    }%
                  </span> : null}
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Card className="full-coverdesign vendor-table-card margin-ecosystem">
              {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
              {/* <DataGrid
                  sx={{ border: "none", height: "409px", width: "100%" }}
                  rows={rows}
                  columns={columns}
                  sortModel={sortModel}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                    columns: {
                      columnVisibilityModel: {
                        vendorCurrentStatus: roleId === 2 ? false : true,
                      }
                    },
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [],
                      },
                    },
                  }}
                  autosizeOptions={{
                    includeOutliers: true,
                    includeHeaders: false,
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  slots={{
                    toolbar: GridToolbar,
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: () => (
                      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px", overflow: "hidden" }}>
                        <CircularProgress />
                      </Box>
                    )
                  }}
                  loading={showLoader}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                    },
                  }}
                  onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                  disableRowSelectionOnClick
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableColumnMenu
                /> */}

              {/*New Table with tabs*/}
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} className="tab-main-heading" aria-label="basic tabs example">
                  <Tab className="active-color-remove" label="Ecosystem" />
                  <Tab className="active-color-remove" label="Approval" />
                </Tabs>
                <Box>
                  {value === 0 &&
                    <div>
                      <DatagridVendor dataList={ecosystemData} />
                    </div>
                  }
                  {value === 1 &&
                    <div>
                      <Approval dataList={approvalData} />
                    </div>
                  }
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
        <ModalDialog
          handleClose={handleClose}
          open={openAdmin}
          onSubmit={removeData}
          contentData={ModalContentAdmin}
        />
        <ModalDialog
          handleClose={handleClose}
          open={openUser}
          onSubmit={removeLink}
          contentData={ModalContentUser()}
        />
      </Container >
    </>
  );
}
