import React, { useEffect, useState } from "react";
import Drawer from '@mui/material/Drawer';
import Tabs from '@mui/material/Tabs';
import FilterIco from '../assets/filter-icon.svg';
import Tab from '@mui/material/Tab';
import IconAlert from '../assets/icon-alert.svg';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { TableContainer, Table, Button,FormControl,FormControlLabel,TableHead, TableBody, TableRow, TableCell, Paper, Box } from '@mui/material';
import DnsImage from '../assets/dns-image.svg';
import LinkIco from '../assets/link-ico.svg';
import HighButton from '../assets/high-button.svg';
import Critical from '../assets/critical-img.svg';


function MappedIssue() {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = useState(0); // State to track the active tab

    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
    };

    // Handle tab change
    const handleChange = (event, newValue) => {
         setValue(newValue); // Set the new active tab
    };
  
    const DrawerList = (
     <Box className="drawerlist" sx={{ width: 800 }} role="presentation" onClick={toggleDrawer(false)}>
        <Box sx={{ width: '100%' }}>
      {/* Tabs */}
      <Box sx={{borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} className="mapping-tab-heading" aria-label="basic tabs example">
          <Tab className="active-color-remove" label="Mapped Issue" />
          <Tab className="active-color-remove" label="Evidence" />
        </Tabs>
      </Box>

      {/* Tab content */}
      <Box className="bordertab-none">
        {/* Content for the first tab*/}
        {value === 0 && (
        <>
             <div className="mapping-issue">
              <img src={IconAlert} alt="icon" className="mr-image"/> Issue Mapped
             </div>
             <div className="issue-mapped-tab">
              <p>A mapped issue indicates that a question is invalidated due to a detected vulnerability, proactively identifying conflicts with expected controls. This serves as an early insight into existing issues.</p>               
             </div>
             <div className="question-mapping" style={{display:'flex'}}>
              <p className="preview-question-heading">Q.1</p>
               <span className="data-text-preview">
                Establish and maintain the secure configuration of enterprise assets (end-user devices, including portable and mobile; network devices; non-computing/IoT devices; and servers) and software (operating systems and applications).
              </span>
              </div>
                            
             <FormControl className="form-group-radiotype">
             <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
            >
                <FormControlLabel className="form-control-labels" value="female"control={<Radio />} label="Yes" />
                <FormControlLabel className="form-control-labels" value="male" control={<Radio />} label="No" />
                <FormControlLabel className="form-control-labels" value="other" control={<Radio />} label="N/A" />
             </RadioGroup>
            </FormControl>
                            
         <Box className="table-top-map" sx={{ overflowX: 'auto' }}> {/* To handle horizontal scroll on small screens */}
          <TableContainer>
             <Table sx={{ minWidth: '100%' }} aria-label="responsive table">
                <TableHead className="table-header-mapping">
                    <TableRow>
                    <TableCell>Issue</TableCell>
                    <TableCell align="left">Category</TableCell>
                    <TableCell align="left">Severity</TableCell>
                    <TableCell align="left">Issue</TableCell>
                    <TableCell align="left"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                 <TableRow>
                    <TableCell>DMARC Record not found</TableCell>
                     <TableCell> 
                         <div className="dns-box">
                          <img src={DnsImage} alt="icon" /> DNS
                        </div>
                    </TableCell>
                       <TableCell align="left" className="button-high-low">
                            <img src={HighButton} alt="icon"/>
                       </TableCell>
                    <TableCell align="left">56</TableCell>
                    <TableCell align="left"> <img src={LinkIco} alt="icon"/></TableCell>
                  </TableRow>
                 <TableRow>
                    <TableCell>DMARC Record not found</TableCell>
                     <TableCell>
                     <div className="dns-box">
                          <img src={DnsImage} alt="icon" /> DNS
                        </div>
                    </TableCell>
                        <TableCell align="left" className="button-high-low">
                          <img src={Critical} alt="icon"/>
                       </TableCell>
                    <TableCell align="left">56</TableCell>
                    <TableCell align="left"> <img src={LinkIco} alt="icon"/></TableCell>
                  </TableRow>
                   <TableRow>
                    <TableCell>DMARC Record not found</TableCell>
                    <TableCell> 
                     <div className="dns-box">
                          <img src={DnsImage} alt="icon" /> DNS
                     </div>
                    </TableCell>
                    <TableCell align="left" className="button-high-low">
                        <img src={HighButton} alt="icon"/>
                       </TableCell>
                    <TableCell align="left">56</TableCell>
                    <TableCell align="left"> <img src={LinkIco} alt="icon"/></TableCell>
                  </TableRow>
                                            
                </TableBody>
             </Table>
          </TableContainer>
        </Box>
    </>
    )}
        
      {/* Content for the second tab*/}
        {value === 1 && (
          <>
            <div className="mapping-issue">
              <img src={IconAlert} alt="icon" className="mr-image"/> Issue Mapped
               </div>
                <div className="question-mapping" style={{display:'flex'}}>
                 <p className="preview-question-heading">Q.1</p>
                  <span className="data-text-preview">
                    Establish and maintain the secure configuration of enterprise assets (end-user devices, including portable and mobile; network devices; non-computing/IoT devices; and servers) and software (operating systems and applications).
                  </span>
                </div>
                <FormControl className="form-group-radiotype">
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group">
                    <FormControlLabel className="form-control-labels" value="female"control={<Radio />} label="Yes" />
                    <FormControlLabel className="form-control-labels" value="male" control={<Radio />} label="No" />
                    <FormControlLabel className="form-control-labels" value="other" control={<Radio />} label="N/A" />
                </RadioGroup>
             </FormControl>
               <div className="evidence-mapped">Evidence Mapped</div> 
                 <div className="evidence-map-questions">
                  <div className="question-mapping" style={{display:'flex'}}>
                  <p className="preview-question-heading">E.1</p>
                   <span className="data-text-preview">
                    Establish and maintain the secure configuration of enterprise assets (end-user devices, including portable and mobile; network devices; non-computing/IoT devices; and servers) and software (operating systems and applications).
                  </span>
                 </div>
                 <div className="flex-views-btn">
                   <div className="flexpdf-page">
                    <span>Soc2.pdf</span>
                    <span>Page 25</span>
                    </div> 
                   <Button variant="contained" className="viewbutton-map">View </Button>
                 </div>
               </div>                             
            </>
          )}
      </Box>
    </Box>
</Box>
      );
  
  return (
    <div>
       <span style={{cursor:'pointer'}} onClick={toggleDrawer(true)}> 
        <img src={FilterIco} alt="icon" />
       </span>
      {/* Set the anchor to 'right' to open the drawer from the right side */}
       <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}

export default MappedIssue;
