import React, { useState } from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import {
    Box,
    Button,
    Card,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import PostRequest from "../components/apiConnections/postRequest";
import Loader from "../Loader";
import "./css/Vendors.css";
// ----------------------------------------------------------------------

export function VendorRequestDocuments({
    handleModelCloseFunction,
    vendorUrl,
    vendorNotAvailableDocuments,
    getVendorDocuments,
    contactDetails,
}) {

    const { enqueueSnackbar } = useSnackbar();
    const [userId, setUserId] = useState(localStorage.getItem("userId"));
    const [showLoader, setshowLoader] = useState(false);
    const [contactPerson, setcontactPerson] = useState('');
    const [formData, setFormData] = useState({
        documents: [{ documentType: '' }],
    });
    const [errors, setErrors] = useState({});
    let availableDocumentTypes;



    const handleRequestDocuments = async () => {
        setshowLoader(true);
        if (validateFields()) {
            const requestDocument = formData.documents
                .map(doc => ({
                    clientID: userId,
                    vendorId: doc.vendorId,
                    vendorDomain: vendorUrl,
                    documentType: doc.documentType._id
                }));
            const payload = {
                contactPersonEmail: contactPerson,
                requestDocument: requestDocument
            }
            try {
                const refreshToken = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                    {},
                    {},
                    "refreshToken"
                );
                if (refreshToken) {
                    PostRequest(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REQUEST_DOCUMENT_TO_VENDOR}`,
                        payload)
                        .then((response) => {
                            enqueueSnackbar(response.data.message, { variant: "success" });
                            setshowLoader(false);
                            handleModelCloseFunction();
                            getVendorDocuments();
                        })
                        .catch((errApi) => {
                            enqueueSnackbar(errApi.data.message, { variant: "error" });
                            setshowLoader(false);
                            handleModelCloseFunction();
                        })
                }
            } catch (error) {
                // no data
                enqueueSnackbar("Requested Url not find", { variant: "error" });
                setshowLoader(false);
                handleModelCloseFunction();
                return false;
            }
          
        }
        else {
            enqueueSnackbar("Please provide valid details", { variant: 'error' });
            setshowLoader(false);
        }
    }

    const validateFields = () => {
        const newErrors = {};
        console.log("contactPerson", !contactPerson)
        if (!contactPerson) newErrors.contactPerson = '*please select Contact Person';

        formData.documents.forEach((document, index) => {
            if (!document.documentType) {
                newErrors[`document-${index}-type`] = '*Please select a document type';
            }
            if (document.isUpload && !document.documentFile) {
                newErrors[`document-${index}-file`] = '*Please upload a document file';
            }
        });
        setErrors(newErrors);
        console.log(Object.keys(newErrors).length === 0);
        return Object.keys(newErrors).length === 0;
    };

    const handleContactPersonChange = (event) => {
        console.log("event.target.value", event.target.value)
        setcontactPerson(event.target.value);
        if (event.target.value) {
            setErrors((prevErrors) => {
                const { contactPerson, ...restErrors } = prevErrors;
                return restErrors;
            });
        }
    };

    const handleDeletedOC = (index) => {
        const updatedDOC = formData.documents.filter((_, i) => i !== index);
        setFormData({ ...formData, documents: updatedDOC });
    };

    return (
        <>
            <Loader show={showLoader} />
            <div >
                <Card sx={{ p: 2, m: 0, width: "100%" }} className="attackpart">
                    <Box>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <ClearIcon
                                    className="cross-icon"
                                    onClick={() => { handleModelCloseFunction() }}
                                ></ClearIcon>
                                <FormControl sx={{ width: "100%" }}>
                                    <Box>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-autowidth-label"
                                                    shrink={contactPerson !== ""}
                                                    sx={{
                                                        '&.Mui-focused': {
                                                            color: contactPerson ? 'primary.main' : 'rgba(255, 255, 255, 0.7)',
                                                        },
                                                    }}>Contact Person</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    value={contactPerson}
                                                    onChange={handleContactPersonChange}
                                                    label="Contact Person"
                                                    error={!!errors.contactPerson}
                                                    helperText={errors.contactPerson}
                                                >
                                                    {contactDetails?.length > 0 ? (
                                                        contactDetails.map((person) => (
                                                            <MenuItem key={person.email} value={person.email}>
                                                                {person.firstName} {person.lastName} {`(${person.email})`}
                                                            </MenuItem>
                                                        ))
                                                    ) : (
                                                        <MenuItem value="" disabled>
                                                            No contact available
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                                {!!errors.contactPerson && (
                                                    <FormHelperText sx={{ color: 'red' }}>{errors.contactPerson}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider sx={{ my: 2 }} />
                                        </Grid>

                                        <Grid item xs={12}>
                                            {formData.documents.map((document, index) => {
                                                availableDocumentTypes = vendorNotAvailableDocuments.filter(
                                                    (type) => !formData.documents.some((doc, i) => doc.documentType?._id === type._id && i !== index)
                                                );
                                                return (
                                                    <Grid container spacing={2} key={index} alignItems="center">
                                                        <Grid item xs={12} md={4}>
                                                            <FormControl fullWidth sx={{ mb: 2 }}>
                                                                <InputLabel id={`doc-type-label-${index}`} shrink={document.documentType !== ""}
                                                                    sx={{
                                                                        '&.Mui-focused': {
                                                                            color: document.documentType ? 'primary.main' : 'rgba(255, 255, 255, 0.7)',
                                                                        },
                                                                    }}
                                                                >Select Document Type</InputLabel>
                                                                <Select
                                                                    labelId={`doc-type-label-${index}`}
                                                                    id={`doc-type-${index}`}
                                                                    name="documentType"
                                                                    value={document.documentType || ''}
                                                                    onChange={(e) => {
                                                                        const updatedDocuments = [...formData.documents];
                                                                        updatedDocuments[index].documentType = e.target.value;
                                                                        setFormData((prev) => ({ ...prev, documents: updatedDocuments }));
                                                                    }}
                                                                    required
                                                                    label="Upload Document Type"
                                                                    error={!!errors[`document-${index}-name`]}
                                                                    helperText={errors[`document-${index}-name`]}
                                                                >
                                                                    {availableDocumentTypes.length > 0 ? (
                                                                        availableDocumentTypes.map((type) => (
                                                                            <MenuItem key={type._id} value={type}>
                                                                                {type.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    ) : (
                                                                        <MenuItem value="" disabled>
                                                                            Not Available
                                                                        </MenuItem>
                                                                    )
                                                                    }
                                                                </Select>
                                                                {!!errors[`document-${index}-type`] && (
                                                                    <FormHelperText sx={{ color: 'red' }} >{errors[`document-${index}-type`]}</FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={1}>
                                                            {index > 0 && (
                                                                <DeleteIcon
                                                                    className="delete-icon-document"
                                                                    onClick={() => handleDeletedOC(index)}
                                                                    sx={{ cursor: 'pointer', color: 'red' }}
                                                                />
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                            {availableDocumentTypes && availableDocumentTypes?.length > 1 && (
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => {
                                                        const updatedDocuments = [...formData.documents, { documentType: '', isUpload: false, documentFile: null, documentName: null }];
                                                        setFormData((prev) => ({ ...prev, documents: updatedDocuments }));
                                                    }}
                                                    sx={{ mt: 2 }}
                                                >
                                                    Add Another Document
                                                </Button>
                                            )}
                                        </Grid>
                                    </Box>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleRequestDocuments}
                                >
                                    Request Document
                                </Button>
                            </Grid>

                        </Grid>
                    </Box>
                </Card>
            </div>
        </>
    );
}



export default VendorRequestDocuments