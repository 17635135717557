import React, { Component, useState, useEffect } from "react";
import "./css/VendorInformation.css";
import { Helmet } from "react-helmet-async";
import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import PostRequest from "../components/apiConnections/postRequest";
import GetRequest from "../components/apiConnections/getRequest";
import { getLocalStoarge } from "../components/common/allFunctions";
import { useDispatch } from "react-redux";
import { changeEcosystemTab } from "../redux/activeTabSlice";
import CalendarTodayIcon from '../assets/calender-icon-select.svg';
import {
  Grid,
  Container,
  TextField,
  Button,
  Card, Box, CardMedia,
  Select,
  Typography,
  InputAdornment
} from "@mui/material";
import Loader from "../Loader";
import DefaultQuestionnaireLogo from '../assets/questionnaire-image.png';
import moment from "moment";
import BackArrow from '../assets/back-arrow.svg';
import AddNewIcon from '../assets/add-calender-new.svg';
import CrossDates from '../assets/cross-dates.svg';
import TickSelect from '../assets/tick-select.svg';
import SendArrow from '../assets/top-arrow-btn.svg';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


export default function VendorInformation() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("location", location)
  const dispatch = useDispatch();
  const data = useParams();
  const [showLoader, setshowLoader] = useState(false);
  const [selectSet, setSelectSet] = useState('');
  const [selectSetId, setSelectSetId] = useState('')
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [deadline, setDeadline] = useState('');
  const [reassessmentDates, setReassessmentDates] = useState([])
  const [userEmail, setUserEmail] = useState(getLocalStoarge("userEmail"));
  const [checkData, setCheckData] = useState(JSON.parse(window.localStorage.getItem("assessmentData")) || '');
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const [setsNames, setSetsNames] = useState([]);
  const [contactPersonList, setContactPersonList] = useState([])

  const [selectedContactPerson, setSelectedContactPerson] = useState('')
  let data_classification_new = [];
  let assess_level_new = [];
  let regulatory_compliance_new = [];
  if (checkData !== "") {
    data_classification_new = checkData?.data_classification.split(',');
    assess_level_new = checkData?.assess_level.split(',');
    regulatory_compliance_new = checkData?.regulatory_compliance.split(',');
  }
  const [data_classification, setDataClassification] = useState(data_classification_new || []);
  const [assess_level, setAccessLevel] = useState(assess_level_new || []);
  const [regulatory_compliance, setRegulatory] = useState(regulatory_compliance_new || []);
  const [mappingData, setMappingData] = useState({
    iso27001Control: [],
    gdpr: [],
    nist: [],
  })
  const [filter, setFilter] = useState({
    iso27001Control: "",
    gdpr: "",
    nist: "",
  }); 
  useEffect(() => {
    getSetName();
    getContactPerson();
    if (checkData !== "") {
      setFirstName(checkData.firstName);
      setLastName(checkData.lastName);
      setEmail(checkData.email);
      setDeadline(checkData.deadline);
      setSelectSetId(checkData.setId);
      setReassessmentDates(checkData.reassessmentDates || []);
    }
    getMappingDataAPI();
  }, [])
  const [filteredSet , setFilteredSet] = useState(setsNames); 
  
  useEffect(() => {
    
    const filteredData = filterSetsFunction();
    setFilteredSet(filteredData); 
  }, [filter])
  
  const filterSetsFunction = () => {
    const { iso27001Control, gdpr, nist } = filter;
    return (!iso27001Control && !gdpr && !nist) ? 
      setsNames
     : 
     setsNames.filter(set => {
      const { mapping } = set;
        return (
          mapping &&
          (mapping.includes(iso27001Control) ||
          mapping.includes(gdpr) ||
          mapping.includes(nist))
        );
    });
  };
 
  const handleChange = (e) => {
    // Case 1 : The user checks the box
    if (e.target.checked) {
      data_classification.push(e.target.value);
    }
    // Case 2  : The user unchecks the box
    else {
      data_classification.shift(e.target.value);
    }
    setDataClassification(data_classification)
  };

  const handleChange1 = (e) => {
    if (e.target.checked) {
      assess_level.push(e.target.value);
    }
    else {
      assess_level.shift(e.target.value);
    }
    setAccessLevel(assess_level)
  };

  const handleChange2 = (e) => {
    if (e.target.checked) {
      regulatory_compliance.push(e.target.value);
    }
    else {
      regulatory_compliance.shift(e.target.value);
    }
    setRegulatory(regulatory_compliance)
  };

  const handleChange3 = event => {
    let value = JSON.parse(event.target.value)
    setSelectSetId(value._id);
    setSelectSet(event.target.value);
  };


  const handleValid = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "firstName") {
      setFirstName(value);
    }
    if (name === "lastName") {
      setLastName(value);
    }
    if (name === "email") {
      setEmail(value);
    }
    if (name === "deadline") {
      setDeadline(value);
    }
  };

  const getSetName = async () => {
    setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_SETS_NAMES}`,
        )
          .then((resSetsName) => {
            const obj = resSetsName.data.data;
            setSetsNames(obj);
            setFilteredSet(obj); 
            console.log(checkData, obj?.length)
            if (checkData.setId && obj?.length) {
              let selectedSet = obj?.find((set) => set._id == checkData.setId)
              if (selectedSet._id) setSelectSet(JSON.stringify(selectedSet))
            }
            setshowLoader(false);
          })
          .catch((err) => {
            enqueueSnackbar("Sets Name not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      enqueueSnackbar("Sets Name not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const getContactPerson = async () => {
    setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_DOMAIN_CONTACT_PERSON}?domain=${vendorUrl}`,
        )
          .then((res) => {
            const response = res.data.data;
            setContactPersonList(response);
            if (checkData.email && Array.isArray(response)) setSelectedContactPerson(JSON.stringify(response?.find((item) => item.email == checkData.email) || ''))
            setshowLoader(false);
          })
          .catch((err) => {
            enqueueSnackbar("Contact person not found", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      enqueueSnackbar("Contact person not found", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const getMappingDataAPI = async () => {
    setshowLoader(true);
    try {
        const refreshToken = await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            {},
            {},
            "refreshToken"
        );
        if (refreshToken) {
            await GetRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_QUESTIONNAIRE_MAPPING_DATA}`,
                {}
            )
                .then((res) => {
                    let data = res.data.data;
                    setshowLoader(false);
                    setMappingData(data)
                })
                .catch((err) => {
                    enqueueSnackbar(err.message, { variant: 'error' });
                    setshowLoader(false);
                })
        } else {
            setshowLoader(false);
        }
    }
    catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
        setshowLoader(false);
    }
  } 

  const do_submit = async (e) => {
    e.preventDefault();
    if (!selectedContactPerson) {
      enqueueSnackbar('Please select contact person', { variant: 'error' })
      return false;
    }
    let contactPerson = JSON.parse(selectedContactPerson)
    console.log(contactPerson, "contact")
    let currentTime = Date.now();
    let todayEpoch = new Date(currentTime).getFullYear() + "-" + ((new Date(currentTime).getMonth() + 1) < 10 ? ("0" + (new Date(currentTime).getMonth() + 1)) : (new Date(currentTime).getMonth() + 1)) + "-" + ((new Date(currentTime).getDate() < 10) ? ("0" + (new Date(currentTime).getDate())) : new Date(currentTime).getDate());

    // if (firstName === '' || firstName === undefined || firstName === null || firstName.length < 3 || !(/^[A-Za-z\s]+$/.test(firstName))) {
    //   enqueueSnackbar('Please enter FirstName (minimum 3 character).', { variant: 'error' });
    //   setFirstName('')
    //   return false;

    // }
    // if (lastName === '' || lastName === undefined || lastName === null || lastName.length < 3 || !(/^[A-Za-z\s]+$/.test(lastName))) {
    //   enqueueSnackbar('Please enter LastName (minimum 3 character).', { variant: 'error' });
    //   setLastName('')
    //   return false;

    // }
    // if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    //   enqueueSnackbar('Please enter valid email address.', { variant: 'error' });
    //   setEmail('');
    //   return false;
    // }
    if ((!deadline) || deadline === null || deadline === '' || (todayEpoch > deadline) || (deadline.length !== 10)) {
      enqueueSnackbar("Please select valid deadline date.", { variant: 'error' });
      setDeadline('');
      return false;
    }
    if ((!selectSet) || selectSet === null || selectSet === '') {
      enqueueSnackbar("Please select Set.", { variant: 'error' })
      setSelectSet('');
      return false;
    }
    setshowLoader(true);
    const filteredData = reassessmentDates.filter(item => item.date !== null && item.deadline !== null);
    setReassessmentDates(filteredData); 
    let payload = {
      firstName: contactPerson?.firstName || "",
      lastName: contactPerson?.lastName || "",
      email: contactPerson?.email,
      data_classification: data_classification.toString(),
      assess_level: assess_level.toString(),
      regulatory_compliance: regulatory_compliance.toString(),
      set: JSON.parse(selectSet).set,
      setType: JSON.parse(selectSet).type,
      setId: selectSetId,
      deadline,
      reassessmentDates:filteredData,
      createdBy: userEmail,
      url: data.url
    };
    try {
      PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CHECK_IF_ASSESSMENT_ALREADY_SENT}`,
        {
          userEmail: userEmail,
          setId: selectSetId,
          email: contactPerson?.email
        }
      )
        .then((res) => {
          const obj = res.data.data;
          setshowLoader(false);
          if (obj.sent === 1) {
            setshowLoader(false)
            enqueueSnackbar(obj.message || "set already sent", { variant: "error" });
            return;
          } else {
            window.localStorage.setItem("assessmentData", JSON.stringify(payload));
            setshowLoader(false);
            navigate(`../questionnaire/${vendorUrl}`, { replace: true, state: location?.state });
          }
        })
        .catch((err) => {
          enqueueSnackbar(err.data?.message || "something went wrong", { variant: "error" });
          setshowLoader(false);
          return false;
        });
    } catch (error) {
      enqueueSnackbar(error.message || "something went wrong", { variant: "error" });
      setshowLoader(false);
      return false;
    }
    return;
  };

  const getCurrentDate = (date) => {
    let parseDate = date
    if(date !== deadline) parseDate = moment(parseDate).add(1,'day')
    let currentDate = new Date(parseDate);
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    let day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleContactPersonChange = (e) => {
    setSelectedContactPerson(e.target.value)
  }

  const handleAddReassessmentDate = () => {
    const length = reassessmentDates.length;
    if (length === 3) {
      enqueueSnackbar("Only three re-assessment date allowed", { variant: "warning" })
      return;
    }
    if (!deadline) {
      enqueueSnackbar("Please select deadline", { variant: "warning" })
      return;
    }
    if (length && !reassessmentDates[length - 1]?.deadline) {
      enqueueSnackbar(`Please select deadline for ${length == 1 ? "first" : "second"} reassessment`, { variant: "warning" })
      return;
    }
    setReassessmentDates([...reassessmentDates.slice(0), { date: null, deadline: null }])
  }
  
  const handleClearFilter = () => { 
    setFilter((prevFilter) => ({
      ...prevFilter,
      iso27001Control: "",
      gdpr: "",
      nist: "",
    })); 
  }

  const handleQuestionPropertyChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value
    }));

  };
  
  return (
    <>
      <Loader show={showLoader} />

      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
       <Grid container spacing={3} className="ipad-space">
         <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h4" sx={{ mb: 0 }}>
           <span className='backlink'>
            <Link to={`/dashboard/assessment`} className='none-line'>
            <img src={BackArrow} alt='icon' />
            </Link> Vendor Information</span>
           </Typography>
          </Grid>
    
           <Grid item xs={12} md={12} lg={12}>
               <div className="d-flex-back-btn">
                {/* <h3 className="vendor-information-head"><span>1</span> Vendor Information</h3> */}
                {/* <Button variant="contained" className="click-to-begin m-l-0 cancel-btn" onClick={() => { navigate(`../${location?.state}`); dispatch(changeEcosystemTab("4")) }}>Back</Button> */}
               </div>
            <form onSubmit={(e) => { do_submit(e) }} >
             <div className="vendor-information">
              <Grid container spacing={3} className="border-bottom">
               <Grid item xs={12} md={12} lg={12}>
                <h3 className="vendor-contact-heading">Vendor's Contact Person</h3>
                </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">{!selectedContactPerson ? "Select Person" : ""}</InputLabel>
                    <TextField
                    labelId="demo-simple-select-label"
                    select
                    defaultValue=""
                    className="contact-person"
                    value={selectedContactPerson || ""}
                    onChange={handleContactPersonChange}
                  >
                    {
                      contactPersonList.map((item, val) => (
                        <MenuItem key={item._id} value={JSON.stringify(item)} >{item.firstName} {item.lastName} {`(${item.email})`}</MenuItem>
                      ))
                    }
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <TextField
                  name="deadline"
                  InputLabelProps={{ shrink: true, required: true }}
                  // placeholder={date}
                  type="date"
                  value={deadline || ""}
                  title="Select Date"
                  className="contact-person"

                  onChange={(event) => handleValid(event)}
                  label="Deadline"
                  // Add min attribute to disable past dates
                  inputProps={{ min: getCurrentDate(new Date()), onKeyDown: (e) => e.preventDefault() }}
                  sx={{ color: 'white' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          src={CalendarTodayIcon}
                          alt="calendar icon"
                          style={{ cursor: 'pointer' }}							 
                          onClick={() => {
                            document.getElementsByName('deadline')[0].showPicker();
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                  </Grid>
                 <Grid item xs={12} md={4} lg={4}>
                    {/* <Button variant="contained" className='reassessments-btn' onClick={handleAddReassessmentDate}>
                    <img src={AddNewIcon} alt="icon"/> Add Reassessments Dates
                    </Button> */}
                 </Grid>
                </Grid>
                
               <Grid container spacing={3} className="re-assessment-date">
                {reassessmentDates?.map((item1, i) => (
                  <>
                  <Grid item xs={12} md={12} lg={12}>
                   <h3 className="vendor-contact-heading">Re-assessment Dates</h3>
                   </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        name="reassessmentDate"
                        InputLabelProps={{ shrink: true, required: true }}
                        // placeholder={date}
                        type="date"
                        value={item1.date || ""}
                        className="contact-person"
                        title="Select Date"
                        onChange={(event) => {
                          let dates = [...reassessmentDates]
                          dates[i].date = event.target.value;
                          setReassessmentDates(dates)
                        }}
                        label="Re-assessment Date"
                        // Add min attribute to disable past dates
                        inputProps={{ min: getCurrentDate(reassessmentDates[i - 1]?.deadline || deadline), onKeyDown: (e) => e.preventDefault() }}
                        sx={{ color: 'white' }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img
                                src={CalendarTodayIcon}
                                alt="calendar icon"
                                style={{ cursor: 'pointer' }}							 
                                onClick={() => {
                                  document.getElementsByName('reassessmentDate')[0].showPicker();
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        name="reassessmentDateDeadline"
                        className="contact-person"
                        InputLabelProps={{ shrink: true, required: true }}
                        // placeholder={date}
                        type="date"
                        value={item1.deadline || ""}
                        title="Select Date"
                        onChange={(event) => {
                          let dates = [...reassessmentDates]
                          dates[i].deadline = event.target.value;
                          setReassessmentDates(dates)
                        }}
                        label="Deadline"
                        // Add min attribute to disable past dates
                        inputProps={{
                          min: getCurrentDate(reassessmentDates[i].date),
                          onKeyDown: (e) => e.preventDefault(),
                          onClick: (e) => {
                            if(!reassessmentDates[i].date){
                              enqueueSnackbar("Please select Re-assessment Date first",{variant:"warning"})
                              e.preventDefault()
                            } 
                          }
                        }}
                        sx={{ color: 'white' }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img
                                src={CalendarTodayIcon}
                                alt="calendar icon"
                                style={{ cursor: 'pointer' }}							 
                                onClick={() => {
                                  document.getElementsByName('reassessmentDateDeadline')[0].showPicker();
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} style={{display:'flex', alignItems:'center'}}>
                     <img src={CrossDates} alt="icon"/> 
                   </Grid>
                  </>
                ))}
                {(reassessmentDates.length < 3) &&
                  <Box sx={{ ml: 'auto', mt: 2 }} >
                    <Button variant="contained" className='reassessments-btn' onClick={handleAddReassessmentDate}>
                    <img src={AddNewIcon} alt="icon"/> Add Reassessments Dates
                    </Button>
                  </Box>
                }
                {/* <Grid item xs={12} md={4} lg={4}>
                  <h3 className="relation mt-0"> Data classification</h3>
                  <p>What kind of data does the vendor hold?</p>
                  <FormControlLabel control={<Checkbox defaultChecked={data_classification.includes("Confidential data")} />} label="Confidential data" value="Confidential data" onChange={handleChange} /><br />
                  <FormControlLabel control={<Checkbox defaultChecked={data_classification.includes("Sensitive data")} />} label="Sensitive data" value="Sensitive data" onChange={handleChange} /><br />
                  <FormControlLabel control={<Checkbox defaultChecked={data_classification.includes("Public data")} />} label="Public data" value="Public data" onChange={handleChange} />
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <h3 className="relation mt-0"> Access level</h3>
                  <p>What kind of access does the vendor hold?</p>
                  <FormControlLabel control={<Checkbox defaultChecked={assess_level.includes("Administrative access")} />} label="Administrative access" value="Administrative access" onChange={handleChange1} /><br />
                  <FormControlLabel control={<Checkbox defaultChecked={assess_level.includes("User access")} />} label="User access" value="User access" onChange={handleChange1} /><br />
                  <FormControlLabel control={<Checkbox defaultChecked={assess_level.includes("Limited access")} />} label="Limited access" value="Limited access" onChange={handleChange1} />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <h3 className="relation mt-0"> Regulatory compliance</h3>
                  <p>Is the vendor compliant with compliance?</p>
                  <FormControlLabel control={<Checkbox defaultChecked={regulatory_compliance.includes("HIPAA")} />} label="HIPAA" value="HIPAA" onChange={handleChange2} /><br />
                  <FormControlLabel control={<Checkbox defaultChecked={regulatory_compliance.includes("GDPR")} />} label="GDPR" value="GDPR" onChange={handleChange2} /><br />
                  <FormControlLabel control={<Checkbox defaultChecked={regulatory_compliance.includes("PCI DSS")} />} label="PCI DSS" value="PCI DSS" onChange={handleChange2} /><br />
                  <FormControlLabel control={<Checkbox defaultChecked={regulatory_compliance.includes("ISO 27001")} />} label="ISO 27001" value="ISO 27001" onChange={handleChange2} />
                </Grid> */}

                {/* <Grid item xs={12} md={12} lg={12}>
                  <div className="line-hr"></div>
                </Grid> */}
                 </Grid>
              </div>
              <p className="vendor-options-below">Please choose the assessment you need to send from the options below.</p>
              <div className="vendor-information mtop-vendor">
                <FormControl>
                  <Grid item xs={12} md={12} lg={12} sx={{ mt: 1 }}>
                    {/* <h3 class="vendor-information-head"><span>2</span>Assessment</h3> */}
                     <div>
                          {/* <FormControl fullWidth sx={{ mb: 2 }}>
                              <Box sx={{ ml: 'auto', mt: 2 }} >
                                <Button variant="contained" className="click-to-begin" onClick={handleClearFilter}>
                                    Clear Filter 
                                </Button>
                              </Box>
                              <Grid container spacing={2} sx={{ marginTop: 0 }}>
          
                                  <Grid item xs={4} md={4}>
                                      <TextField
                                          select
                                          fullWidth
                                          name='iso27001Control'
                                          label="ISO 27001 Control"
                                          value={filter?.iso27001Control }
                                          onChange={handleQuestionPropertyChange}
                                          inputProps={{ 'aria-label': 'Select ISO 27001 Control' }}
                                          InputLabelProps={{
                                            shrink: Boolean(filter?.iso27001Control), // Force the label to shrink based on the value
                                          }}
                                      >
                                          <MenuItem value="" disabled>
                                              Select ISO 27001 Control
                                          </MenuItem>
                                          {Array.isArray(mappingData?.iso27001Control) ? mappingData.iso27001Control.map((item, index) => (
                                              <MenuItem key={index} value={item}>
                                                  {item}
                                              </MenuItem>
                                          )) : null}
                                      </TextField>
                                  </Grid>

                          
                                  <Grid item xs={4} md={4}>
                                      <TextField
                                          select
                                          fullWidth
                                          name='gdpr'
                                          label="GDPR"
                                          value={filter?.gdpr || "" }
                                          onChange={handleQuestionPropertyChange}
                                          inputProps={{ 'aria-label': 'Select GDPR' }}
                                          InputLabelProps={{
                                            shrink: Boolean(filter?.gdpr), // Force the label to shrink based on the value
                                          }}
                                      >
                                          <MenuItem value="" disabled>
                                              Select GDPR
                                          </MenuItem>
                                          {Array.isArray(mappingData?.gdpr) ? mappingData.gdpr.map((item, index) => (
                                              <MenuItem key={index} value={item}>
                                                  {item}
                                              </MenuItem>
                                          )) : null}
                                      </TextField>
                                  </Grid>

                            
                                  <Grid item xs={4} md={4}>
                                      <TextField
                                          select
                                          fullWidth
                                          name='nist'
                                          label="NIST"
                                          value={filter?.nist}
                                          onChange={handleQuestionPropertyChange}
                                          inputProps={{ 'aria-label': 'Select NIST' }}
                                          InputLabelProps={{
                                            shrink: Boolean(filter?.nist), // Force the label to shrink based on the value
                                          }}
                                      >
                                          <MenuItem value="" disabled>
                                              Select NIST
                                          </MenuItem>
                                          {Array.isArray(mappingData?.nist) ? mappingData.nist.map((item, index) => (
                                              <MenuItem key={index} value={item}>
                                                  {item}
                                              </MenuItem>
                                          )) : null}
                                      </TextField>
                                  </Grid>
                              </Grid>
                          </FormControl> */}
                          {/* <Button variant="contained" className='addsection' onClick={() => addOption(sectionIndex, questionIndex)}>
                              Add Option
                          </Button> */}
                    </div>
                    <Grid container xs={12} spacing={3} sx={{ mt: 1 }} className="assessment-card">
                      {Array.isArray(filteredSet) ? filteredSet.map((item, i) => (
                        <Grid item xs={12} md={3} lg={3} key={i}>
                          <Card
                            className={item._id === selectSetId ? "selected-assessment-card" : ""}
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectSetId(item._id);
                              setSelectSet(JSON.stringify(item));
                            }}
                            
                          >
                            <CardMedia
                              className="card-media-img"
                              component="img"
                              sx={{
                                objectFit: "cover",
                                width: "80px",
                                height: "80px",
                                mx: 'auto',
                                borderRadius: "50%"
                              }}
                              image={item.logo ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_QUESTIONNAIRE_LOGO}/${item.logo}` : DefaultQuestionnaireLogo}
                              alt={item.set}
                              onError={(e) => {
                                e.target.onerror = null;
                                // e.target.src = '/path/to/fallback-image.jpg'; 
                              }}
                             />
                            <Box className="name-optional">{item.set}</Box>
                            <img src={TickSelect} className="tick-selected-icon" alt="icon"/>
                           </Card>
                         </Grid>
                       )) : null}
                     </Grid>
                   </Grid>
                  </FormControl>
              </div>
              <Grid item xs={12} md={12} lg={12} className="btn-right">
                    {/* <Button variant="contained" className="click-to-begin m-l cancel-btn">
                      Previous
                      </Button> */}
                    {/* <LoadingButton  className="click-to-begin" type="submit" variant="contained" >
                        Next Step
                      </LoadingButton>  */}
                    {/* <Link to={`../questionnaire/${vendorUrl}`}> */}
                     <Button type="submit" variant="contained" className="next-steps-button">
                      Next Step
                     </Button>
                    {/* </Link> */}
                  </Grid>
              </form>
            </Grid>
          </Grid>
      </Container>

    </>
  );
}

