import React, { useEffect, useState } from 'react'
import { Button, Card, Grid, Container, styled, TextField, InputAdornment, Box, IconButton, Menu, MenuItem, FormControl, Select, } from '@mui/material';
import { Helmet } from "react-helmet-async";
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import GetRequest from '../components/apiConnections/getRequest';
import PostRequest from '../components/apiConnections/postRequest';
import { filter } from "lodash";
import QuestionnairePreview from './QuestionnairePreview';
import './css/addQuestionnaire.css'
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteQuestionnaire from '../components/modal/deleteQuestionnaire';
import { DataGrid } from '@mui/x-data-grid';
import UploadQuestionnaireSet from '../components/modal/uploadQuestionnaireSet';
import QuestionnaireLogo from '../assets/questionnaire-image.png';
import UploadIcon from '../assets/upload-icon-new.svg';
import Add from '../assets/plus-vendor.svg';
import NewHeader from '../layouts/dashboard/header/NewHeader';
import SearchIcon from '../assets/searchico.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EyeMenu from '../assets/eye-menu.svg';
import Trash from '../assets/trahs.svg';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Questionnaire Found</Box>
    </StyledGridOverlay>
  );
}

// Menu component for the three-dot menu
function RowMenu({ onViewClick, onDeleteClick }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} className='three-dots-btn'>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className='rowmenu'
      >
        <MenuItem onClick={onViewClick} className='border-bot-line'> <img src={EyeMenu} /> View</MenuItem>
        <MenuItem onClick={onDeleteClick}> <img src={Trash} /> Remove</MenuItem>
      </Menu>
    </>
  );
}

export default function QuestionanrieLibary() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setShowLoader] = useState(false)
  const [questionnaireSets, setQuestionnaireSets] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isNotFound, setIsNotFound] = useState(false);
  const [orderBy, setOrderBy] = useState("set");
  const [order, setOrder] = useState("asc");
  const [activeQuestionnaireSet, setActiveQuestionnaireSet] = useState({})
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showDeleteQuestionnaireModal, setShowDeleteQuestionnaireModal] = useState(false)
  const [rows, setRows] = useState([])
  const [showQuestionnaireImportModal, setShowQuestionnaireImportModal] = useState(false)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [columns, setColumns] = useState([
    { field: 'id', headerName: 'No.', editable: false, maxWidth: 80, flex: 1 },
    {
      field: 'logos', headerName: 'Logo', maxWidth: 120, flex: 1,
      sortable: false,
      headerAlign: 'left', align: 'left',
      renderCell: (params) => {
        return (
          <Box className=" icons-aligns-new icons-aligns  add-questionnaire-action mui-icons-questionnaire">
            {params.row?.logo ? <img src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_QUESTIONNAIRE_LOGO}/${params.row?.logo}`} alt='logo' /> :
              <img src={QuestionnaireLogo} alt='logo' />}
          </Box>
        )
      }
      ,
    },
    { field: 'set', headerName: 'Name', editable: false, minWidth: 360, flex: 1, headerAlign: 'left', align: 'left' },
    { field: "totalCategories", headerName: "Total Categories", editable: false, minWidth: 140, flex: 1, headerAlign: 'center', align: 'center' },
    {
      field: 'action', headerName: 'Action', minWidth: 150, flex: 1,
      sortable: false,
      headerAlign: 'center', align: 'center',
      renderCell: (params) => {
        return (
          <Box className=" icons-aligns-new icons-aligns  add-questionnaire-action mui-icons-questionnaire">
            <Button sx={{ marginRight: 10 }} onClick={() => configureQuestionnaireSet(params.row)}><VisibilityIcon /></Button>
            <Button sx={{ marginRight: 10 }} onClick={() => navigate("/dashboard/questionnaire-library/edit-questionnaire", { state: params.row })}><ModeEditOutlineIcon /></Button>
            <Button onClick={() => { configureDeleteQuestionnaireSet(params.row) }}><DeleteIcon /></Button>
          </Box>
        )
      }
      ,
    },
  ])

  const columnsNew = [
    {
      field: 'id',
      headerName: '#',
      flex: 0.5,
      className: 'serial-number',
      disableColumnMenu: true,
    },
    {
      field: 'set',
      headerName: 'Name',
      flex: 1.1,
      minWidth: 170,  // Ensure minimum width
      disableColumnMenu: true,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
          {params.row?.logo ? <span className="logo-image-vendor"><img className="attack-surface-list-logo" src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_QUESTIONNAIRE_LOGO}/${params.row?.logo}`} onError={(e) => {
                e.target.onerror = null;
                e.target.src = QuestionnaireLogo;
              }} alt='logo' /></span>
             : <span className="logo-image-vendor"><img className="attack-surface-list-logo" src={QuestionnaireLogo} alt='logo'/></span>
          }
          <span className='dots-words-limit' style={{ display: 'flex', gap: '6px' }}>
            {params.value}
          </span>
        </div>
      ),
    },
    {
      field: 'status', headerName: 'Status', flex: 1,
      minWidth: 125,
      disableColumnMenu: true,
      renderCell: () =>
        <span className="onboard-green stroke">Publish</span>
    },
    {
      field: 'datecreate', headerName: 'Date Created',
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
      renderCell: () => <>24/03/23</>
    },
    {
      field: 'industry', headerName: 'Created By',
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
      renderCell: () => <>Alex Morgan</>
    },
    {
      field: 'totalCategories', headerName: 'Total Categories',
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
      renderCell: (params) => <>{params.value}</>
    },
    {
      field: 'menu1', headerName: '',
      flex: 0.2,
      disableColumnMenu: true,
      renderCell: (params) => <RowMenu onViewClick={() => configureQuestionnaireSet(params.row)} onDeleteClick={() => configureDeleteQuestionnaireSet(params.row)} />
    },
  ];

  useEffect(() => {
    getQuestionnaireSet()
  }, [])
  useEffect(() => {
    getQuestionnaireSet(paginationModel.page + 1, paginationModel.pageSize);
  }, [paginationModel]);

  const [searchText, setSearchText] = useState('');
  const [filteredRows, setFilteredRows] = useState(rows);
  const [pageSize, setPageSize] = useState(5);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);

    const filtered = rows.filter((row) =>
      Object.values(row).some((field) =>
        String(field).toLowerCase().includes(value)
      )
    );

    setFilteredRows(filtered);
  };

  const getQuestionnaireSet = async () => {
    setShowLoader(true);
    setRows([])
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_CUSTOM_QUESTIONNAIRE_SET_LIST}`,
          {}
        )
          .then((resChart) => {
            let data = resChart.data.data;
            setIsNotFound(!(data.length > 0));
            if (!data.length) return;
            data?.reverse();
             setQuestionnaireSets([...data?.map((d) => ({ ...d, totalCategories: d.setCategories?.length || 0 }))])
            // enqueueSnackbar('Questionnaire set added successfully', { variant: 'success' });
            setRows([...data?.map((d, i) => ({ ...d, id: i + 1, totalCategories: d.setCategories?.length || 0 }))])
            setShowLoader(false);
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.message, { variant: 'error' });
            setShowLoader(false);
          })
      } else {
        setShowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setShowLoader(false);
    }
  };

  const handleAddQuestionnaireClick = () => {
    navigate('./add-questionnaire');
  };

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  function applySortFilter(array, comparator, query) {

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) => _user.requestAttackUrl.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const handleEditClick = () => {
    setShowPreviewModal(false);
  };

  const configureQuestionnaireSet = (set) => {
    let arr = []
    set.setCategories?.forEach((data, i) => {
      let obj = {}
      obj.category = data.category;
      obj.questions = data.questions
      arr = [...arr, obj]
    })
    setActiveQuestionnaireSet({ ...set, sections: arr })
    setShowPreviewModal(true)
  };

  const configureDeleteQuestionnaireSet = (set) => {
    setActiveQuestionnaireSet({ ...set })
    setShowDeleteQuestionnaireModal(true)
  };
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };



  return (
    <>
      {/* <Loader show={showLoader} /> */}
      <Helmet>
        <title> Questionnaire | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Questionnaire library" />
        {/* <Stack
            direction="row"
            alignItems="end"
            justifyContent="end"
            mb={0}
          >
            <Button
              sx={{ marginRight: "10px" }}
              variant="contained"
              className="add-questionnaire-button"
              startIcon={<Iconify icon="material-symbols:upload" />}
              onClick={() => setShowQuestionnaireImportModal(true)}
            >
              Upload Questionnaire
            </Button>
            <Button
              variant="contained"
              className="add-questionnaire-button"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleAddQuestionnaireClick}
            >
              Add Questionnaire
            </Button>
        </Stack> */}
        <Grid container spacing={3} sx={{ mb: 3 }} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            <Card className="full-coverdesign margin-questionnaire">
              {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
              <div className="cover-table">
                {/*<Box className="bg-dark-assessment" sx={{ width: '100%', backgroundColor: '#fff', border: 'none', borderRadius: '15px' }}>
              <div style={{ width: '100%' }}>
                <DataGrid
                  sx={{ border: 'none', height: '400px', width: '100%' }}
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  autosizeOptions={{
                    columns: ['id', 'firstName', 'lastName'],
                    includeOutliers: true,
                    includeHeaders: false,
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  slots={{
                    toolbar: GridToolbar,
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: () => (
                      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px", overflow: "hidden" }}>
                        <CircularProgress />
                      </Box>
                    )
                  }}
                  loading={showLoader}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                    },
                  }}
                  disableRowSelectionOnClick
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableColumnMenu
                />
              </div>
              </Box>*/}
                {/* <QuestionnaireTable/> */}
                {/*New Table Static*/}
                <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: '6px', left: '8px' }}>
                  <FormControl className='select-entries' variant="outlined" size="small">
                    <Select
                     value={paginationModel.pageSize}
                    onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </FormControl>
                  <span className='entries-per'>entries per page</span>
                </div>
                <div className='question-buttons'>
                  <Button
                    sx={{ marginRight: "0px" }}
                    variant="contained"
                    className="add-questionnaire-button"
                    // startIcon={<Iconify icon="material-symbols:upload" />}
                    onClick={() => setShowQuestionnaireImportModal(true)}
                  >
                    <img src={UploadIcon} alt='icon' style={{ marginRight: "13px" }} />  Upload Questionnaire
                  </Button>
                  <Button
                    variant="contained"
                    className="add-questionnaire-button"
                    // startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={handleAddQuestionnaireClick}
                  >
                    <img src={Add} alt='icon' style={{ marginRight: "13px" }} /> Add Questionnaire
                  </Button>
                </div>
                <Box
                  sx={{
                    width: '100%',
                    '& .MuiDataGrid-columnHeaders': {
                      minWidth: '100%',
                      overflowX: 'hidden',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                      minWidth: '100%',
                      overflowX: 'auto',
                    },
                  }}
                >
                  <TextField
                    variant="outlined"
                    placeholder="Search…"
                    value={searchText}
                    onChange={handleSearch}
                    className='searh-text-field'
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={SearchIcon} alt='icon' />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <DataGrid
                  loading={showLoader}
                    rows={rows} // Ensure you're using the filtered rows
                    columns={columnsNew}
                    pageSize={pageSize}
                    rowHeight={70}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20]}
                    onPaginationModelChange={handlePaginationModelChange}
                    paginationModel={paginationModel}
                    paginationMode="client"
                    disableSelectionOnClick
                    autoHeight
                    className='datagrid-table'
                    sx={{
                      '& .MuiDataGrid-columnHeaders': {
                        minWidth: '100%', // Column headers take up full width
                      },
                      '& .MuiDataGrid-virtualScroller': {
                        minWidth: '100%', // Rows take up full width
                      },
                    }}
                  />
                </Box>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {showPreviewModal ? <QuestionnairePreview
        open={showPreviewModal}
        handleClose={() => setShowPreviewModal(false)}
        onClose={handleEditClick}
        sections={activeQuestionnaireSet.sections}
        description={activeQuestionnaireSet.description}
        title={activeQuestionnaireSet.set}
        showSubmit={false}
      /> : null}
      {showDeleteQuestionnaireModal ? <DeleteQuestionnaire
        open={showDeleteQuestionnaireModal}
        handleClose={() => setShowDeleteQuestionnaireModal(false)}
        onClose={() => setShowDeleteQuestionnaireModal(false)}
        setId={activeQuestionnaireSet.setId}
        handleSuccess={() => getQuestionnaireSet()}
      /> : null}
      {showQuestionnaireImportModal ? <UploadQuestionnaireSet
        open={showQuestionnaireImportModal}
        handleClose={() => setShowQuestionnaireImportModal(false)}
        onClose={() => setShowQuestionnaireImportModal(false)}
        handleSuccess={() => { setShowQuestionnaireImportModal(false); getQuestionnaireSet() }}
      /> : null}
    </>
  )
}
